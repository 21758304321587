<template>
    <ExternalPageWrapper :maxWidth="280">
        <Container :borderRadius="22" width="100%" hideOverflow>
            <!-- SSO -->
            <ssoButtons
                @google-clicked="stytchSSO('createaccount', 'google')"
                @microsoft-clicked="stytchSSO('createaccount', 'microsoft')"
                @linkedin-clicked="stytchSSO('createaccount', 'linkedin')"
            />

            <!-- Form -->
            <Form @submitted="createAccount" validateOnSubmit>
                <Stack :padding="[18, 20, 20, 20]" direction="column" gap="1.25rem">
                    <!-- Error -->
                    <Stack v-if="error" :padding="[16, 20]" borderBottom>
                        <ErrorMessage>
                            {{ error }}
                        </ErrorMessage>
                    </Stack>
                    <Stack direction="column" gap="1.125rem">
                        <TextInput
                            v-model="name"
                            name="name"
                            label="Name"
                            placeholder="Johnny Appleseed"
                            autocomplete="name"
                            autofocus
                            required
                        />
                        <TextInput
                            v-model="emailAddress"
                            type="email"
                            name="emailAddress"
                            label="Email Address"
                            placeholder="johnny@appleseed.com"
                            autocomplete="email"
                            required
                        />
                        <TextInput
                            v-model="password"
                            type="password"
                            name="password"
                            label="Password"
                            :minlength="8"
                            placeholder="••••••••••"
                            autocomplete="new-password"
                            required
                        />
                    </Stack>
                    <Button submit :loading="loading" fullWidth iconAfter="ArrowUpRight">
                        Create Account
                    </Button>
                </Stack>
            </Form>
        </Container>
        <Stack :padding="[20, 0, 0, 0]" direction="column" gap="0.375rem">
            <Text size="body-extra-small" align="center" as="p">
                <router-link to="/login">
                    <Text as="span" color="foreground-minus-three">
                        Already have an account? Login here.
                    </Text>
                </router-link>
            </Text>
        </Stack>
    </ExternalPageWrapper>
</template>

<script setup lang="ts">
// Imports
import { useCreateAccount } from '@/composition/auth/useCreateAccount'

// Layout
import ExternalPageWrapper from '@/components/layout/externalPages/ExternalPageWrapper.vue'
import ssoButtons from '@/components/connectors/sso/ssoButtons.vue'

// Components
import {
    Form,
    Stack,
    TextInput,
    Text,
    Button,
    Container,
    ErrorMessage,
} from '@opteo/components-next'

import { useStytchSignIn } from '@/composition/auth/useStytchSignIn'

const { loading, createAccount, name, emailAddress, password, error } = useCreateAccount()
const { stytchSSO } = useStytchSignIn()
</script>

<style scoped lang="scss"></style>
