<template>
    <Table
        v-if="teamAccountsLoading || filteredLinkedAccounts.length > 0"
        ref="tableRef"
        :columns="linkedTableHeaders"
        :rows="filteredLinkedAccounts"
        selectionMode="filter"
        v-model="selectedLinked"
        @select="handleRowSelect($event, true)"
        @select:all="selectAllAccounts"
        :loading="teamAccountsLoading"
        :columnWidths="linkedColumnWidths"
        :perPage="24"
        paginationMode="intersection"
        :rowHeight="60"
        :headerHeight="52"
        :height="tableHeight"
        stickyHeader
        :borderRadius="0"
        cellSpacing="medium"
        :sort="{ column: 'spend30d', direction: 'desc' }"
        color="transparent"
        expectData
    >
        <!-- Header -->
        <template #changelogEmail-header>
            <Text as="span" :wrap="false">Changelog Email</Text>
            <Popout
                :modelValue="showChangelogEmailPopout"
                trigger="mouseenter"
                placement="top"
                :offset="[0, 12]"
            >
                <Info />
                <template #content>
                    <Container
                        color="background-plus-two"
                        shadow="large"
                        :padding="[14, 18]"
                        :borderRadius="18"
                        :width="322"
                        hideOverflow
                    >
                        <Text as="p" size="body-small" align="left">
                            Whenever a change is pushed via Opteo, the email address you select in
                            this column will be displayed in the <b>Change History</b> (or
                            equivalent) section inside your platform account.
                        </Text>
                        <Spacer :height="10" />
                        <Text as="p" size="body-small" align="left">
                            To use another email address, import the parent account by clicking the
                            <b>Import Account</b> button, using your preferred email address to sign
                            in.
                        </Text>
                    </Container>
                </template>
            </Popout>
        </template>
        <!-- Rows -->
        <template #name-data="{ row }">
            <LinkedAccountsAccountName :account="row" linked />
        </template>
        <template #spend30d-data="{ row }">
            <LinkedAccountsSpendCell :account="row" />
        </template>
        <template #connectionStatus-data="{ row }">
            <LinkedAccountsConnectionStatusCell :account="row" />
        </template>
        <template #changelogEmail-data="{ row }">
            <LinkedAccountsChangelogSelect
                :account="row"
                :availableConnections="row.availableConnections"
                :accountId="row.accountId"
            />
        </template>
        <template #teamMembers-data="{ row }">
            <LinkedAccountsTeamMembers :account="row" isLinked />
        </template>
    </Table>

    <!-- All accounts are unlinked -->
    <EmptyState
        v-else-if="linkedAccountsCount === 0"
        icon="ExclamationExtraLarge"
        headline="No Linked Accounts"
        :height="tableHeight"
        :contentMaxWidth="350"
        :padding="[48, 0]"
        hideContainer
    >
        <template #message>
            You currently have no accounts linked to Opteo. Switch to the <b>Unlinked</b> tab to
            link an existing account, or click <b>Import Account</b> to import a new one and link it
            to Opteo.
        </template>
    </EmptyState>
</template>

<script setup lang="ts">
// Imports
import { ref, computed } from 'vue'

// Composition
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'

// Components
import {
    Table,
    Text,
    Popout,
    Container,
    EmptyState,
    Info,
    Spacer,
    useMediaQuery,
} from '@opteo/components-next'
import LinkedAccountsAccountName from '@/components/linkedAccounts/LinkedAccountsAccountName.vue'
import LinkedAccountsSpendCell from '@/components/linkedAccounts/LinkedAccountsSpendCell.vue'
import LinkedAccountsTeamMembers from '@/components/linkedAccounts/LinkedAccountsTeamMembers.vue'
import LinkedAccountsChangelogSelect from '@/components/linkedAccounts/LinkedAccountsChangelogSelect.vue'
import LinkedAccountsConnectionStatusCell from '@/components/linkedAccounts/LinkedAccountsConnectionStatusCell.vue'

// Setup
const {
    linkedTableHeaders,
    linkedColumnWidths,
    filteredLinkedAccounts,
    teamAccountsLoading,
    handleRowSelect,
    selectAllAccounts,
    selectedLinked,
    linkedAccountsCount,
    showChangelogEmailPopout,
} = useLinkedAccounts()
const tableRef = ref()
const { above1024 } = useMediaQuery()
const tableHeight = computed(() => {
    if (above1024.value === true) {
        return 'calc(100dvh - 208px)'
    } else {
        return 'calc(100dvh - 288px)'
    }
})

// defineExpose
defineExpose({ tableRef })
</script>
