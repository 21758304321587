import { format } from 'date-fns'
import maxBy from 'lodash-es/maxBy'
import { computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useUser } from '@/composition/user/useUser'
import { LS_BILLING_LOCKED } from '@/lib/cookies'
import { showSnackbar } from '@opteo/components-next'
import { Billing, ReportUpgrades } from '@opteo/types'

import { Endpoint, useAPI } from '../api/useAPI'

import type Stripe from 'stripe'

type UserType = Billing.OpteoSubscriptionStatus | 'update_cc' | 'cancelled'

const CANCELLATION_REASONS = {
    agency_trialing: {
        feature_missing: `There's a key feature we feel is missing.`,
        improvements_not_helpful: `Improvements aren't helpful.`,
        other_tool: `We've decided to use another tool.`,
        too_expensive: 'Opteo is too expensive.',
        covid: 'Coronavirus (COVID-19) related.',
        not_listed: 'Reason not listed above.',
    },
    advertiser_trialing: {
        marketing_manager: 'We hired a full-time digital marketing manager.',
        new_account: 'We need help building the account from scratch.',
        other_tool: `We've decided to use another tool.`,
        too_expensive: 'Opteo is too expensive.',
        covid: 'Coronavirus (COVID-19) related.',
        not_listed: 'Reason not listed above.',
    },
    agency_active: {
        feature_missing: `There's a key feature we feel is missing.`,
        improvements_not_helpful: `Improvements are no longer helpful.`,
        other_tool: `We've decided to use another tool.`,
        too_expensive: 'Opteo is too expensive.',
        covid: 'Coronavirus (COVID-19) related.',
        not_listed: 'Reason not listed above.',
    },
    advertiser_active: {
        marketing_manager: 'We hired a full-time digital marketing manager.',
        improvements_not_helpful: `Improvements are no longer helpful.`,
        other_tool: `We've decided to use another tool.`,
        too_expensive: 'Opteo is too expensive.',
        covid: 'Coronavirus (COVID-19) related.',
        not_listed: 'Reason not listed above.',
    },
}

type CancellationReasonsTypes = keyof typeof CANCELLATION_REASONS

export function useSubscription() {
    const { loading: userLoading } = useUser()
    const {
        data: stripeCustomer,
        loading: stripeCustomerLoading,
        mutate,
    } = useAPI<Billing.OpteoCustomer>(Endpoint.GetStripeCustomer)

    const {
        data: coupon,
        loading: couponLoading,
        mutate: mutateCoupon,
    } = useAPI<Billing.ExtendedStripeCoupon | null>(Endpoint.GetStripeCustomerCoupon)

    const { data: trialLength, loading: trialLengthLoading } = useAPI<number>(
        Endpoint.CalculateTrialLength
    )

    const loading = computed(
        () => userLoading.value || stripeCustomerLoading.value || trialLengthLoading.value
    )

    const { userInfo } = useUser()

    const latestSubscription = computed<Billing.OpteoSubscription | undefined>(() =>
        maxBy(stripeCustomer.value?.subscriptions?.data, 'created')
    )

    const userCancellationReasons = computed(() => {
        const type = `${userInfo.value?.avatar}_${userType.value}` as CancellationReasonsTypes

        const specificReasons = CANCELLATION_REASONS[type] ?? CANCELLATION_REASONS.agency_trialing

        if (!specificReasons) {
            return []
        }

        return Object.keys(specificReasons).map(reason => {
            return {
                value: reason,
                // @ts-ignore
                label: specificReasons[reason],
            }
        })
    })

    const userType = computed<UserType>(() => {
        if (!latestSubscription.value) {
            return 'cancelled'
        }

        const { status } = latestSubscription.value
        const hasPaymentMethod = !!stripeCustomer.value?.default_payment_method

        // Very late on payments, and has payment method: block and prompt for CC
        if (status === 'unpaid' && hasPaymentMethod) {
            return 'update_cc'
        }

        // Very late on payments, and has NO payment method: block and prompt for re-trial
        if (status === 'unpaid' && !hasPaymentMethod) {
            return 'cancelled'
        }

        // A little late on payments, but has a payment method: allow them to continue using the app
        if (status === 'past_due' && hasPaymentMethod) {
            // if they're past due but have a payment method, allow them to continue using the app
            return 'active'
        }

        // A little late on payments, and have no payment method: block and prompt for CC
        if (status === 'past_due' && !hasPaymentMethod) {
            return 'update_cc'
        }

        return status
    })

    const isBillingLocked = computed(() => {
        const lockable_user_types: UserType[] = [
            'cancelled',
            'canceled',
            'incomplete',
            'incomplete_expired',
            'unpaid',
            'update_cc',
        ]
        return lockable_user_types.includes(userType.value)
    })

    // On mount and on change, update the local storage value
    const setBillingLocked = (isLocked: boolean) =>
        localStorage.setItem(LS_BILLING_LOCKED, isLocked ? '1' : '0')

    onMounted(() => setBillingLocked(isBillingLocked.value))
    watch(isBillingLocked, newVal => setBillingLocked(newVal))

    const isTrialing = computed(() => latestSubscription.value?.status === 'trialing')

    const creditCard = computed(() => stripeCustomer.value?.default_payment_method?.card)

    const paymentMethod = computed(() => stripeCustomer.value?.default_payment_method?.id)

    const nextChargeDate = computed(() => {
        if (!latestSubscription.value) {
            return
        }

        return format(
            (isTrialing.value
                ? (latestSubscription.value.trial_end ?? 0)
                : (latestSubscription.value.current_period_end ?? 0)) * 1000,
            'MMMM do yyyy'
        )
    })

    const cancelsOn = computed(
        () =>
            latestSubscription.value?.cancel_at &&
            format(latestSubscription.value.cancel_at * 1000, 'MMMM do yyyy')
    )

    const route = useRoute()
    const router = useRouter()

    onMounted(() => {
        const queryValue = Object.values(ReportUpgrades.UrlSearchParam).find(urlSearchParam => {
            return Object.values(route.query).some(routeQuery => routeQuery === urlSearchParam)
        })

        if (!queryValue) return

        const wasCouponPreviouslyApplied =
            queryValue === ReportUpgrades.UrlSearchParam.err &&
            coupon.value?.id === Billing.Coupon.ReportUpgrades20Percent3Months

        const Messages = {
            [ReportUpgrades.UrlSearchParam.couponApplied]: 'Discount code applied successfully.',
            [ReportUpgrades.UrlSearchParam.err]:
                'Discount code could not be applied. If this error persists, please contact support.',
            couponPreviouslyApplied: 'Discount code already applied.',
        } as const

        const message = wasCouponPreviouslyApplied
            ? Messages.couponPreviouslyApplied
            : Messages[queryValue]

        if (!message) return

        showSnackbar({
            message,
            timeout: 5000,
        })

        router.replace({ query: {} })
    })

    return {
        stripeCustomer,
        latestSubscription,
        userType,
        loading,
        creditCard,
        paymentMethod,
        coupon,
        nextChargeDate,
        isTrialing,
        couponLoading,
        mutateCoupon,
        trialLength,
        cancelsOn,
        userCancellationReasons,
        mutate,
        isBillingLocked,
    }
}
