<template>
    <Stack ref="stackRef">
        <Skeleton v-if="loading" :width="60" :height="38" color="secondary" />
        <Tooltip v-else :content="`${selectedGroup?.label}`" :delay="[800, 0]">
            <AvatarSelect
                type="accountGroup"
                :modelValue="selectedGroup?.value"
                :buttonSize="28"
                :items="groupsList"
                nested-button="Plus"
                :popoutWidth="220"
                :appendTo="stackEl"
                @update:modelValue="onModelUpdate"
            />
        </Tooltip>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, computed } from 'vue'
import { Account, AccountGroup } from '@opteo/types'
import { useAccountGroups } from '@/composition/accountGroups/useAccountGroups'

// Components
import { Stack, AvatarSelect, Skeleton, Tooltip } from '@opteo/components-next'

// Props
const props = defineProps({
    accountId: {
        type: String as PropType<Account.ID>,
        required: true,
    },
    accountGroupId: {
        type: [Number, null] as PropType<AccountGroup.ID | null>,
        required: true,
    },
    loading: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Null ID for ungrouped is not a valid model value
// we'll use this constant to work with the avatar select component
const UNGROUPED_ID = -1

const { accountGroups, ungroupedGroup, assignAccountToGroup } = useAccountGroups()

const groupsList = computed(() => {
    // ungrouped as first option
    const groups = [ungroupedGroup, ...(accountGroups.value ?? [])]
    return groups.map(group => {
        return {
            label: group.name,
            color: group.colour,
            value: group.accountGroupId ?? UNGROUPED_ID,
        }
    })
})

const selectedGroup = computed(() => {
    if (!props.accountGroupId) {
        return groupsList.value[0] // ungrouped
    }
    return groupsList.value.find(group => group.value === props.accountGroupId)
})

const onModelUpdate = (newValue: number | string) => {
    // selecting with the shortkey return a string instead of a number ??
    const parsedId = typeof newValue !== 'number' ? parseInt(newValue) : newValue
    // revert back to null for ungrouped
    const accountGroupId = parsedId === UNGROUPED_ID ? null : parsedId

    assignAccountToGroup({ accountId: props.accountId, accountGroupId })
}

// appendTo
const stackRef = ref<InstanceType<typeof Stack> | null>(null)
const stackEl = computed(() => {
    return stackRef.value?.$el ?? document.body
})
</script>
