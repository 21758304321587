<template>
    <Stack>
        <Button
            @click="handleModalOpened"
            :disabled="isUnlinkable(account)"
            color="secondary"
            size="small"
            iconAfter="PencilSmall"
        >
            <template #before>
                <Stack :gap="-4" firstOnTop width="auto">
                    <Skeleton
                        v-if="account.id?.includes('skeleton')"
                        :width="16"
                        :height="16"
                        color="foreground"
                    />
                    <ProfileImage
                        v-else
                        v-for="i in isLinked ? activeUsers.slice(0, 1) : [1]"
                        :key="isLinked ? i.name : i"
                        :size="16"
                        :imageUrl="i.profileUrl"
                        :boringAvatar="getAvatarUrl(i.userId)"
                        :hideRole="true"
                        :noImage="!isLinked"
                        skeletonColor="foreground"
                    />
                </Stack>
            </template>
            <Skeleton
                v-if="account.id?.includes('skeleton')"
                :width="68"
                :height="10"
                color="foreground"
            />
            <span v-else>{{ activeUsers.length }} Connected</span>
        </Button>
    </Stack>

    <Modal title="Team Members" v-model="teamModalOpened">
        <template #titlePrefix>
            <Avatar
                :color="mapHexToAvatarColor(account.color)"
                :platform="account.platform"
                show-account-type
            >
                {{ account.initials }}
            </Avatar>
        </template>
        <template #content>
            <Container
                :borderRadius="20"
                :gap="0"
                :width="above480 ? 380 : '100%'"
                :minHeight="updatedTeamMembers.length > 6 ? 457 : 'unset'"
                hideOverflow
            >
                <Stack direction="column" :gap="0" width="100%">
                    <!-- Table Header -->
                    <Stack
                        :padding="updatedTeamMembers.length > 6 ? [14, 20] : [16, 20]"
                        :gap="20"
                        borderBottom
                    >
                        <Stack :gap="16" width="auto">
                            <Tooltip
                                :content="aboveMobile ? 'Select All' : undefined"
                                :delay="[600, 0]"
                                placement="top"
                            >
                                <Checkbox
                                    @click="toggleAllUsers"
                                    :modelValue="allUsersActive"
                                    :indeterminate="indeterminate"
                                />
                            </Tooltip>
                            <Text as="span" size="table-header" :wrap="false">Team Member</Text>
                        </Stack>
                        <Stack v-if="updatedTeamMembers.length > 6" :gap="0" :padding="0">
                            <TextInput
                                name="searchUsers"
                                v-model="searchedUser"
                                icon="Search"
                                size="small"
                                autocomplete="off"
                            />
                        </Stack>
                    </Stack>
                    <!-- Table Body -->
                    <Scroller :maxHeight="394" width="100%" grow>
                        <template v-if="filteredTeamMembers.length > 0">
                            <ClickableRow
                                v-for="user in filteredTeamMembers"
                                :key="user.userId"
                                @click="toggleUser(user.userId)"
                                :padding="[14, 20]"
                            >
                                <Stack :gap="16" align="center">
                                    <Checkbox
                                        :modelValue="user.active"
                                        @click="toggleUser(user.userId)"
                                        readOnly
                                    />
                                    <Stack align="center" :gap="14">
                                        <ProfileImage
                                            :size="28"
                                            :imageUrl="user.profileUrl ?? undefined"
                                            :boringAvatar="getAvatarUrl(user.userId)"
                                        />
                                        <Stack
                                            direction="column"
                                            align="start"
                                            :padding="[2, 0, 0]"
                                            :gap="3"
                                        >
                                            <Text size="button" :wrap="false">{{ user.name }}</Text>
                                            <Text
                                                size="body-extra-small"
                                                color="foreground-minus-two"
                                                :wrap="false"
                                            >
                                                {{ user.email }}
                                            </Text>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </ClickableRow>
                        </template>
                        <!-- Search returns no matching team members -->
                        <EmptyState
                            v-else-if="searchedUser.length && filteredTeamMembers.length === 0"
                            :headline="searchedUser"
                            message="No results matching your search query"
                            :borderRadius="0"
                            :hideContainer="true"
                            :height="updatedTeamMembers.length > 6 ? 394 : '100%'"
                        />
                    </Scroller>
                </Stack>
            </Container>
        </template>
        <template #buttons>
            <Button color="secondary" @click="teamModalOpened = false">Cancel</Button>
            <Button
                v-if="isLinked"
                ref="updateTeamSettingsButton"
                @click="updateTeamSettings()"
                iconBefore="User"
                :loading="updatingTeam"
            >
                Update Team Members
            </Button>
            <Button
                v-if="!isLinked"
                ref="updateTeamAndLinkButton"
                @click="updateTeamSettings()"
                iconBefore="Link"
                color="green"
                :loading="updatingTeam"
                :disabled="linkingIsOverLimit"
            >
                Confirm and Link Account
            </Button>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { Team, User } from '@opteo/types'
import {
    Avatar,
    Modal,
    Button,
    Tooltip,
    ProfileImage,
    Text,
    TextInput,
    showSnackbar,
    hideSnackbar,
    Checkbox,
    Stack,
    Container,
    Scroller,
    EmptyState,
    ClickableRow,
    Skeleton,
    useMediaQuery,
} from '@opteo/components-next'

import { useLinkedAccounts, OpteoButton } from '@/composition/linkedaccounts/useLinkedAccounts'

import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import cloneDeep from 'lodash-es/cloneDeep'
import { Endpoint, authRequest } from '@/composition/api/useAPI'
import { mapHexToAvatarColor } from '@/composition/utils/utils'

const props = defineProps<{ account: Team.Account; isLinked: boolean }>()

const updatedTeamMembers = ref(cloneDeep(props.account.teamMembers))
const teamModalOpened = ref(false)

const {
    accountsCountRemaining,
    accounts30dSpendRemaining,
    mutateAllDomains,
    clearSelectedAccounts,
    planLimitWarning,
    searchedUnlinkedAccounts,
    userId,
    linkingAccounts,
    isUnlinkable,
} = useLinkedAccounts()

const { getAvatarUrl } = useBoringAvatar()

const { aboveMobile, above480 } = useMediaQuery()

const activeUsers = computed(() => {
    // Check if account.teamMembers exists and is an array
    if (!props.account.teamMembers || !Array.isArray(props.account.teamMembers)) {
        return [] // Return empty array as a fallback
    }

    // Now it's safe to filter the array
    return props.account.teamMembers.filter(u => u.active)
})

const allUsersActive = computed(() => updatedTeamMembers.value.every(user => user.active))
const someUsersActive = computed(() => updatedTeamMembers.value.some(user => user.active))
const indeterminate = computed(() => someUsersActive.value && !allUsersActive.value)

const moreTeamMembers = ref(false)

function handleModalOpened() {
    if (linkingIsOverLimit.value) {
        planLimitWarning.value = true
        return
    }
    updatedTeamMembers.value = cloneDeep(props.account.teamMembers)
    clearSelectedAccounts()
    teamModalOpened.value = true

    if (linkingAccounts.value) {
        const user = updatedTeamMembers.value.find(user => user.userId === userId.value)
        if (user && !user.active) {
            user.active = true
        }
    }
}

// Toggling Team Members
function toggleAllUsers() {
    const active = !allUsersActive.value
    updatedTeamMembers.value.forEach(user => (user.active = active))
}

function toggleUser(userId: User.ID) {
    const user = updatedTeamMembers.value.find(user => user.userId === userId)

    if (!user) return

    user.active = !user.active
}

// Updating Team Members Logic
const updateTeamSettingsButton = ref<OpteoButton>()
const updateTeamAndLinkButton = ref<OpteoButton>()

const linkingIsOverLimit = computed(
    () =>
        !props.isLinked &&
        (accountsCountRemaining.value - 1 < 0 ||
            accounts30dSpendRemaining.value - props.account.spend30d < 0)
)

// Updates team members for a specific account
const updatingTeam = ref(false)

async function updateTeamSettings() {
    updatingTeam.value = true
    const usersToLink = updatedTeamMembers.value
        .filter(
            user =>
                user.active &&
                !props.account.teamMembers.find(u => u.userId === user.userId)?.active
        )
        .map(user => user.userId)

    const usersToUnlink = updatedTeamMembers.value
        .filter(
            user =>
                !user.active &&
                props.account.teamMembers.find(u => u.userId === user.userId)?.active
        )
        .map(user => user.userId)

    if (!usersToLink.length && !usersToUnlink.length) {
        teamModalOpened.value = false
        updatingTeam.value = false
        return
    }

    const button = props.isLinked ? updateTeamSettingsButton : updateTeamAndLinkButton

    try {
        if (usersToUnlink.length) {
            await authRequest(Endpoint.UpdateLinkedAccountStatus, {
                body: {
                    userIds: usersToUnlink,
                    accountIds: [props.account.accountId],
                    newStatus: false,
                },
            })
        }

        if (usersToLink.length) {
            await authRequest(Endpoint.UpdateLinkedAccountStatus, {
                body: {
                    userIds: usersToLink,
                    accountIds: [props.account.accountId],
                    newStatus: true,
                },
            })
        }

        await mutateAllDomains()

        updatingTeam.value = false

        teamModalOpened.value = false

        showSnackbar({
            message: props.isLinked
                ? `Team members updated successfully`
                : `Account linked and team members updated successfully`,
            timeout: 4000,
            actionText: 'Dismiss',
            actionHandler: () => hideSnackbar(),
        })

        searchedUnlinkedAccounts.value = ''
    } catch {
        updatingTeam.value = false

        showSnackbar({
            message: `Something went wrong updating team members`,
            timeout: 4000,
            actionText: 'Dismiss',
            actionHandler: () => hideSnackbar(),
        })
    }
}

// Hovering Team Members Logic
const hoveredProfile = ref()

function setHoveredProfile(name?: string) {
    hoveredProfile.value = name
}

// Search Functionality
const searchedUser = ref('')

const filteredTeamMembers = computed(() => {
    if (!searchedUser.value) return updatedTeamMembers.value

    const searchTermLower = searchedUser.value.toLowerCase()
    const filtered = updatedTeamMembers.value.filter(user =>
        user.name.toLowerCase().includes(searchTermLower)
    )

    return filtered
})

watch(teamModalOpened, open => {
    if (!open && searchedUser.value.length > 0) {
        searchedUser.value = ''
    }
})
</script>

<style scoped lang="scss">
.avatar-border {
    @include relative;
    @include br-999;
    padding: 0.125rem;
    @include bg-background;
}

.three-dots {
    @include relative;
    height: 1.5rem;
    width: 1.5rem;
    @include br-max;
    @include flex-center;
    @include bg-background-plus-three;
}
</style>
