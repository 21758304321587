export const enum Endpoint {
    // Misc
    TestAccess = 'auth/testAccess',
    DoesUserHasConnectedAccounts = 'auth/doesUserHasConnectedAccounts',
    UnsubEmailLink = 'auth/updateAlertSettingsFromLink',
    GetGoogleAdsOauthUrl = 'auth/getAwOauthUrl',
    GetMicrosoftAdsOauthUrl = 'auth/getMicrosoftConnectionUrl',
    GetMetaAdsOauthUrl = 'auth/getMetaConnectionUrl',
    GetLinkedInAdsOauthUrl = 'auth/getLinkedInConnectionUrl',
    TrackUserAction = 'action/trackUserAction',
    GetPlatformConnectionStatus = 'auth/getPlatformConnectionStatus',
    GetConfirmationCode = 'auth/getConfirmationCode',
    SetCSRFToken = 'auth/setCSRFToken',
    GetAccountIdFromDomainId = 'reporting/getAccountIdFromDomainId',

    // User
    GetUser = 'auth/getUserInfo',
    ChangeUserPassword = 'auth/changeUserPassword',
    UpdateUserInfo = 'auth/updateUserInfo',
    UpdateAutoCloseImprovements = 'auth/updateAutoCloseImprovements',
    DoesUserhaveConnectedAccounts = 'auth/doesUserhaveConnectedAccounts',
    IsFirstLogin = 'auth/isFirstLogin',
    GetUserAccounts = 'auth/getUserAccounts',
    GetUserAccountsMetrics = 'reporting/getUserAccountsRowData',

    GetDomainList = 'auth/getDomainList',
    GetDomainListMetrics = 'reporting/getDomainListData',
    GetUserInfoFromHash = 'auth/getUserInfoFromHash',
    SetNewPasswordFromHash = '/api/auth/setNewPasswordFromHash',
    GetCannyToken = 'auth/getCannyToken',
    CreateStripeSetupIntent = 'auth/createStripeSetupIntent',
    UnlinkDomainFromUsers = 'auth/unlinkUsers',
    DismissOnboardingStep = 'action/dismissOnboardingStep',
    GetIsUserTeamEnrolledInFeature = 'auth/getIsUserTeamEnrolledInFeature',

    // Alerts
    ListAlerts = 'reporting/getUserAlertsV2',
    SetAlertsAllRead = 'reporting/markAllChecked',
    GetEmailSettings = 'alert/getEmailSettings',
    GetSlackSettings = 'alert/getSlackSettings',
    SaveEmailSettings = 'alert/saveEmailSettings',
    GetAccountEmailSettings = 'alert/getAccountEmailSettings',
    SaveSlackSettings = 'alert/saveSlackSettings',
    MuteAccountEmails = 'alert/muteAccountEmails',

    // Billing
    GetPricingPlans = 'billing/getPricingPlans',
    GetStripeCustomer = 'billing/getStripeCustomer',
    UpdateCustomerCc = 'billing/updateCustomerCc',
    GetStripeCustomerCoupon = 'billing/getStripeCustomerCoupon',
    CalculateTrialLength = 'billing/calculateTrialLength',
    GetFirstUnpaidInvoice = 'billing/getFirstUnpaidInvoice',
    SaveCouponForUser = 'billing/saveCouponForUser',
    CancelSubscription = 'billing/cancelSubscription',
    HandleSuccessfullRenewal = 'billing/handleSuccessfullRenewal',
    RenewSubscription = 'billing/renewSubscription',
    GetCustomerCharges = 'billing/getCustomerCharges',
    GetInvoiceData = 'auth/getInvoiceData',
    UpdateInvoiceEmail = 'auth/updateInvoiceEmail',
    UpdateVatNumber = 'auth/updateVatNumber',
    UpdateInvoiceAddressData = 'auth/updateInvoiceAddressData',
    UpdateStripePlan = 'billing/updateStripePlan',
    CreateStripeCheckoutSession = 'billing/createStripeCheckoutSession',

    // Team Settings
    GetTeam = 'teamSettings/getTeam',
    GetTeamAccounts = 'teamSettings/getTeamAccounts',
    UpdateLinkedAccountStatus = 'teamSettings/updateLinkedAccountStatus',
    AddTeamMember = 'teamSettings/addTeamMember',
    SetTeamMemberRole = 'teamSettings/setTeamMemberRole',
    RemoveTeamMember = 'teamSettings/removeTeamMember',
    SyncGoogleAccountsToDb = 'teamSettings/syncGoogleAccountsToDb',
    SyncMicrosoftAccountsToDb = 'teamSettings/syncMicrosoftAccountsToDb',
    SetAccountConnection = 'teamSettings/setAccountConnection',
    GetPossibleConnectionsForAccount = 'teamSettings/getPossibleConnectionsForAccount',

    // Improvements
    GetImprovement = 'reporting/getImprovement',
    GetCompletedImprovement = 'reporting/getCompletedImprovement',
    GetDismissedImprovement = 'reporting/getDismissedImprovement',
    ListImprovements = 'reporting/getImprovementsThrottled',
    ListCompletedImprovements = 'reporting/getCompletedImprovements',
    ListDismissedImprovements = 'reporting/getDismissedImprovements',
    PushImprovement = 'reporting/implementImprovement',
    GetImprovementCounts = 'reporting/getImprovementCounts',
    GetImprovementPreferences = 'reporting/getImprovementPreferences',
    SetImprovementPreferences = 'reporting/setImprovementPreferences',
    SaveDismissalFeedback = 'action/saveDismissalFeedback',
    ImplementImprovement = 'action/implementImprovement',
    DismissImprovement = 'action/dismissImprovement',
    RevertDismissal = 'action/revertDismissal',
    GetAllAdgroupAds = 'reporting/getAllAdgroupAds',
    GetPausedAds = 'reporting/getPausedAds',
    GetSelectableCampaignsForNegativeKeyword = 'reporting/getSelectableCampaignsForNegativeKeyword',
    GetMobileAppMetadata = 'reporting/getMobileAppMetadata',
    CreateCustomSharedSetWithCampaigns = 'action/createCustomSharedSetWithCampaigns',
    GetCampaignsAndAdGroupsMS = 'reporting/getCampaignsAndAdGroupsMS',
    GetNegativeKeywordListsMS = 'reporting/getNegativeKeywordListsMS',

    // Campaigns
    GetCampaignsSample = 'reporting/getCampaignsSample',
    GetCampaignsAndAdgroups = 'reporting/getCampaignsAndAdgroups',

    // Ads
    GetSQRSampleAd = 'reporting/getSQRSampleAd',

    // Keywords
    GetSourceKeywordBid = 'reporting/getSourceKeywordBid',

    // Account Groups
    GetAccountGroups = 'accountGroups/getAccountGroups',
    CreateAccountGroup = 'accountGroups/createAccountGroup',
    AssignAccountToGroup = 'accountGroups/assignAccountToGroup',
    AssignAccountToManager = 'accountGroups/assignAccountToManager',

    // CampaignGroups Groups
    GetCampaignGroups = 'accountSettings/getCampaignGroups',
    ResetCampaignGroups = 'accountSettings/resetCampaignGroups',
    CreateCampaignGroup = 'accountSettings/createCampaignGroup',
    DeleteCampaignGroup = 'accountSettings/deleteCampaignGroup',
    UpdateCampaignGroup = 'accountSettings/updateCampaignGroup',
    MoveCampaignToGroup = 'accountSettings/moveCampaignToGroup',

    // Shared sets
    GetSharedNegativeLists = 'reporting/getSharedNegativeLists',

    // Images
    DeleteImage = 'action/deleteImage',
    UploadImage = 'action/uploadImage',
    Avatar = 'render/avatar.svg',

    // Account
    GetAccountInfo = 'auth/getAccountInfo',
    TrackAccountVisit = 'auth/trackAccountVisit',
    SaveAccountDisplayName = 'accountSettings/saveAccountDisplayName',
    SaveAccountColorPreference = 'accountSettings/saveAccountColorPreference',

    // Domain
    GetDomainInfo = 'auth/getDomainInfoV2',
    GetDomainsWithLogo = 'auth/getDomainsWithLogo',
    SaveAccountBudget = 'action/saveAccountBudget',
    GetDomainBudget = 'reporting/getBudget',
    GetDomainBudgetVariance = 'reporting/getBudgetAcceptableVariance',
    GetCountry = 'reporting/getCountry',
    GetCountryTld = 'reporting/getCountryTld',
    SaveCountry = 'action/saveCountry',
    GetTimezone = 'reporting/getTimezone',
    GetCpiOverride = 'reporting/getCpiOverride',
    SaveCpiOverride = 'action/saveCpiOverride',
    GetSitelinkExamples = 'reporting/getInspiringUrlData',
    GetPhoneNumber = 'reporting/getPhoneNumber',
    GetAccountPausedStatus = 'reporting/getAccountPausedStatus',
    ReactivatePausedCampaigns = 'action/reactivatePausedCampaigns',
    GetOcid = 'accountSettings/getOcid',
    SaveOcid = 'accountSettings/saveOcid',
    MarkAccountSetupSeen = 'accountSettings/markAccountSetupSeen',

    // Performance Mode, Goals and Conversion actions
    UpdatePerformanceMode = 'accountSettings/updatePerformanceMode',
    GetAccountConversionActions = 'accountSettings/getAccountConversionActions',
    UpdateAccountConversionActions = 'accountSettings/updateAccountConversionActions',
    GetAccountPerformanceGoals = 'accountSettings/getAccountPerformanceGoals',
    UpdateAccountPerformanceGoals = 'accountSettings/updateAccountPerformanceGoals',

    // Analytics
    GetGaOauthUrl = 'auth/getGaOauthUrl',
    CheckAccountGAConnection = 'auth/checkAccountGAConnection',
    RevokeRefreshToken = 'auth/revokeRefreshToken',
    SaveGoogleAnalyticsAccountDetails = 'auth/saveGoogleAnalyticsAccountDetails',
    GetUserProfileIDAndName = 'auth/getUserProfileIDAndName',
    GetAnalyticsProfiles = 'auth/getAnalyticsProfiles',
    GetGALinkingDate = 'auth/getGALinkingDate',
    GetAllGAGroupConnections = 'auth/getAllGAGroupConnections',

    // Slack
    RemoveSlack = 'alert/removeSlack',
    GetSlackOAuth = 'alert/getSlackOAuth',

    // Notes
    GetAllNotes = 'calendar/getAllChanges',
    AddNote = 'calendar/addNote',
    DeleteNote = 'calendar/deleteNote',
    UpdateNote = 'calendar/updateNote',

    // Performance
    GetPerformanceData = 'performance/getPerformanceData',
    GetPerformanceAccountData = 'performance/getAccountData',
    GetAllSegments = 'reporting/getAllSegments',

    // Scorecard
    GetCoverPageData = 'scorecard/getCoverPageData',
    SetCoverPageData = 'scorecard/setCoverPageData',
    GenerateScorecard = 'scorecard/generateScorecard',
    GetScorecard = 'scorecard/getScorecard',
    GetScorecardSectionBreakdown = 'scorecard/getScorecardSectionBreakdown',
    GetScorecardSectionBreakdownFromShareableLink = 'scorecard/getScorecardSectionBreakdownFromShareableLink',
    GetScorecardList = 'scorecard/getScorecardList',
    GenerateShareableLink = 'scorecard/generateShareableLink',
    GetScorecardFromShareableLink = 'scorecard/getScorecardFromShareableLink',
    GetShareableLinkViewers = 'scorecard/getShareableLinkViewers',
    DeleteScorecard = 'scorecard/deleteScorecard',
    GetRsas = 'rsa/getRsas',

    // Reports
    GetReports = 'reports/getReports',
    ArchiveReport = 'reports/archiveReport',
    RestoreReport = 'reports/restoreReport',
    DeleteReport = 'reports/deleteReport',
    DuplicateReport = 'reports/duplicateReport',
    GetReport = 'reports/getReport',
    GetReportPreferences = 'reports/getReportPreferences',
    UpdateDefaultTemplate = 'reports/updateDefaultTemplate',
    UpdateDefaultTheme = 'reports/updateDefaultTheme',
    DownloadReport = 'reports/downloadReport',
    GetNewReportInfo = 'reports/getNewReportInfo',
    GetBaseReportTemplate = 'reports/getBaseReportTemplate',
    GetUserReportTemplates = 'reports/getUserReportTemplates',
    CreateReportTemplate = 'reports/createNewReportTemplate',
    EditReportTemplate = 'reports/editReportTemplate',
    DeleteReportTemplate = 'reports/deleteReportTemplate',
    CreateReport = 'reports/createReport',
    UpdateReport = 'reports/updateReport',
    CancelSchedule = 'reports/cancelSchedule',
    UpdateSlideStatus = 'reports/updateSlideStatus',
    GetReportSchedules = 'reports/getReportSchedules',
    UpdateSlideText = 'reports/updateSlideText',
    SaveWorkSummary = 'reports/saveWorkSummary',

    // Experiments
    GetCampaignsAndExperiments = 'experiments/getCampaignsAndExperiments',
    GetAccountExperimentData = 'experiments/getAccountExperimentData',
    CompleteExperimentRequest = 'experiments/completeExperimentRequest',
    CheckExperimentStatus = 'experiments/checkExperimentStatus',
    CreateExperimentRequest = 'experiments/createExperiment',
    DeleteExperimentRequest = 'experiments/deleteExperiment',

    // RSA writer
    GetRsaSelectionData = 'rsa/getRsaSelectionData',
    SetAdStatus = 'rsa/setAdStatus',
    PublishRsa = 'rsa/publishRsa',
    SaveDraft = 'rsa/saveDraft',
    DeleteDraft = 'rsa/deleteDraft',
    GenerateAiRsaContent = 'rsa/generateAiRsaContent',
    GetCompetitorAds = 'rsa/getCompetitorAds',

    // ChatOPT
    getChatEntities = 'chat/getChatEntities',
    getChatHistory = 'chat/getChatHistory',
    sendChatMessage = 'chat/sendChatMessage',
    deleteChat = 'chat/deleteChat',
    updateChatTitle = 'chat/updateChatTitle',

    // Impact Statistics
    GetImpactStatisticsCostSavings = 'impactStatistics/getCostSavings',
    GetImpactStatisticsTimeSavings = 'impactStatistics/getTimeSavings',
    GetImpactStatisticsCriticalAlerts = 'impactStatistics/getCriticalAlerts',
    GetImpactStatisticsUserStatistics = 'impactStatistics/getUserStatistics',
    GetCalendarHeatMap = 'impactStatistics/getCalendarHeatMap',
    GetAllAccountsForTeam = 'impactStatistics/getAllAccountsForTeam',

    // nGram Tool
    GetNgramCampaigns = 'ngram/getNgramCampaigns',
    GetNgramData = 'ngram/getNgramData',
    SaveNgramsToDB = 'ngram/saveNgramsToDB',
    GetKeywordMetrics = 'ngram/getKeywordMetrics',
    GetSearchTermMetrics = 'ngram/getSearchTermMetrics',
    AddNegativeNgrams = 'ngram/addNegativeNgrams',
    GetNgramAffectedKeywords = 'ngram/getNgramAffectedKeywords',
    CheckNgramCache = 'ngram/checkNgramCache',
    RequestPmaxAnalysis = 'ngram/requestPmaxAnalysis',
    GetPmaxRequestProgressStatus = 'ngram/getPmaxRequestProgressStatus',
    DispatchHighPriorityNgramJob = 'ngram/dispatchHighPriorityNgramJob',
    GetNgramToolHistory = 'ngram/getNgramToolHistory',
}
