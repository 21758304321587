<template>
    <Stack ref="stackRef">
        <Skeleton v-if="loading" :width="60" :height="38" color="secondary" />
        <Tooltip v-else :content="`${selectedManager?.label}`" :delay="[800, 0]">
            <AvatarSelect
                type="user"
                :modelValue="selectedManager?.value"
                :items="managersList"
                :buttonSize="28"
                nested-button="Plus"
                :popoutWidth="232"
                :appendTo="stackEl"
                @update:modelValue="onModelUpdate"
            />
        </Tooltip>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, computed } from 'vue'
import { Account, User } from '@opteo/types'
import { useAccountGroups } from '@/composition/accountGroups/useAccountGroups'
import { useTeam } from '@/composition/user/useTeam'

// Components
import { Stack, AvatarSelect, Skeleton, Tooltip } from '@opteo/components-next'

// Props
const props = defineProps({
    accountId: {
        type: String as PropType<Account.ID>,
        required: true,
    },
    managerUserId: {
        type: [Number, null] as PropType<User.ID | null>,
        required: true,
    },
    loading: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

const { unassignedManager, assignAccountToManager } = useAccountGroups()
const { team } = useTeam()

// Null ID for unassigned is not a valid model value
// we'll use this constant to work with the avatar select component
const UNASSIGNED_ID = -1

const managersList = computed(() => {
    return [
        // unassigned as first option
        {
            label: unassignedManager.name,
            value: UNASSIGNED_ID,
            noImage: true,
        },
        ...(team.value ?? []).map(({ user_id, name, avatar_filename }) => ({
            label: name,
            value: user_id,
            boringAvatar: `https://api.opteo.io/render/avatar.svg?userId=${user_id}`,
            imageUrl: avatar_filename || undefined,
        })),
    ]
})

const selectedManager = computed(() => {
    if (!props.managerUserId) {
        return managersList.value[0] // unassigned
    }
    return managersList.value.find(manager => manager.value === props.managerUserId)
})

const onModelUpdate = (newValue: number | string) => {
    // selecting with the shortkey return a string instead of a number ??
    const parsedId = typeof newValue !== 'number' ? parseInt(newValue) : newValue
    // revert back to null for unassigned
    const managerUserId = parsedId === UNASSIGNED_ID ? null : parsedId

    assignAccountToManager({ accountId: props.accountId, managerUserId: managerUserId })
}

// appendTo
const stackRef = ref<InstanceType<typeof Stack> | null>(null)
const stackEl = computed(() => {
    return stackRef.value?.$el ?? document.body
})
</script>
