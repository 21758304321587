<template>
    <Stack ref="stackRef" :gap="10">
        <Stack v-if="account.id?.includes('skeleton')" :height="15" align="center" width="auto">
            <Skeleton :width="Math.random() * 20 + 56" :height="12" />
        </Stack>
        <Text v-else as="span" size="table-cell">
            <Money :value="+account.spend30d?.value" currency="USD" />
        </Text>
        <Popout
            v-if="account.currencyCode !== 'USD' && !account.id?.includes('skeleton')"
            :modelValue="showInfoPopout"
            :appendTo="stackEl"
            :offset="[0, 14]"
            trigger="mouseenter"
            placement="top"
        >
            <Info />
            <template #content>
                <Container
                    color="background-plus-two"
                    shadow="large"
                    :padding="[13, 18]"
                    :borderRadius="18"
                    :width="286"
                    hideOverflow
                >
                    <Text as="p" size="body-small" align="left">
                        The monthly spend for this account has been converted into USD to ensure
                        spend figures are comparable across accounts.
                    </Text>
                </Container>
            </template>
        </Popout>
    </Stack>
</template>

<script lang="ts" setup>
// Imports
import { ref, computed } from 'vue'

// Types
import { Team } from '@opteo/types'

// Components
import { Stack, Text, Money, Popout, Container, Info, Skeleton } from '@opteo/components-next'

// Props
const props = defineProps<{ account: Team.Account }>()

// Setup
const showInfoPopout = ref(false)

// appendTo
const stackRef = ref<InstanceType<typeof Stack> | null>(null)
const stackEl = computed(() => {
    return stackRef.value?.$el ?? document.body
})
</script>

<style scoped lang="scss"></style>
