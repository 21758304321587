<template>
    <Modal
        v-model="open"
        :title="
            linkingAccounts
                ? `Confirm and Link
                    ${pluralise(selectedUnlinkedAccountsCount, 'Account', 'Accounts')}`
                : 'Assign Team Members'
        "
        @close="closeModal"
    >
        <template #content>
            <Container
                :borderRadius="20"
                :gap="0"
                :width="above480 ? 380 : '100%'"
                :minHeight="teamMembers.length > 6 ? 457 : 'unset'"
                hideOverflow
            >
                <Stack direction="column" :gap="0" width="100%">
                    <!-- Table Header -->
                    <Stack
                        :padding="teamMembers.length > 6 ? [14, 20] : [16, 20]"
                        :gap="20"
                        borderBottom
                    >
                        <Stack :gap="16" width="auto">
                            <Tooltip
                                :content="aboveMobile ? 'Select All' : undefined"
                                :delay="[600, 0]"
                                placement="top"
                            >
                                <Checkbox
                                    :modelValue="allTeamMembersAssigned"
                                    :indeterminate="someTeamMembersAssigned"
                                    @click="assignAllTeamMembers"
                                />
                            </Tooltip>
                            <Text as="span" size="table-header" :wrap="false">Team Member</Text>
                        </Stack>
                        <Stack v-if="teamMembers.length > 6" :gap="0" :padding="0">
                            <TextInput
                                name="searchTeamMembers"
                                v-model="searchedTeamMember"
                                icon="Search"
                                size="small"
                                autocomplete="off"
                            />
                        </Stack>
                    </Stack>
                    <!-- Table Body -->
                    <Scroller :maxHeight="394" width="100%" grow>
                        <template v-if="filteredTeamMembers.length > 0">
                            <ClickableRow
                                v-for="user in filteredTeamMembers"
                                :key="user.userId"
                                @click.capture="assignTeamMember(user.userId)"
                                :padding="[14, 20]"
                            >
                                <Stack :gap="16" align="center">
                                    <Checkbox
                                        :modelValue="assignedTeamMembers.includes(user.userId)"
                                        @click="assignTeamMember(user.userId)"
                                        readOnly
                                    />
                                    <Stack align="center" :gap="14">
                                        <ProfileImage
                                            :size="28"
                                            :imageUrl="user.profileUrl ?? undefined"
                                            :boringAvatar="getAvatarUrl(user.userId)"
                                        />
                                        <Stack
                                            direction="column"
                                            align="start"
                                            :padding="[2, 0, 0]"
                                            :gap="3"
                                        >
                                            <Text size="button">{{ user.name }}</Text>
                                            <Text
                                                size="body-extra-small"
                                                color="foreground-minus-two"
                                            >
                                                {{ user.email }}
                                            </Text>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </ClickableRow>
                        </template>
                        <!-- Search returns no matching team members -->
                        <EmptyState
                            v-else-if="
                                searchedTeamMember.length && filteredTeamMembers.length === 0
                            "
                            :headline="searchedTeamMember"
                            message="No results matching your search query"
                            :borderRadius="0"
                            :hideContainer="true"
                            :height="teamMembers.length > 6 ? 394 : '100%'"
                        />
                    </Scroller>
                </Stack>
            </Container>
        </template>
        <template #buttons>
            <template v-if="linkingAccounts">
                <Button @click="closeModal" color="secondary">Cancel</Button>
                <Button
                    @click="updateLinkedAccountStatusWithTeamMembers"
                    :loading="updatingLinkedAccounts"
                    color="green"
                    iconBefore="Link"
                >
                    Confirm and Link
                    {{ pluralise(selectedUnlinkedAccountsCount, 'Account', 'Accounts') }}
                </Button>
            </template>
            <template v-else>
                <Button
                    ref="removeTeamMembersButton"
                    color="secondary"
                    @click="assignTeamMembersToAccounts(false)"
                    :loading="removingTeamMembers"
                    iconBefore="UserMinus"
                >
                    Disconnect <span v-if="above480">Selected</span>
                </Button>
                <Button
                    ref="addTeamMembersButton"
                    @click="assignTeamMembersToAccounts(true)"
                    :loading="addingTeamMembers"
                    iconBefore="UserPlus"
                >
                    Connect <span v-if="above480">Selected</span>
                </Button>
            </template>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, watch } from 'vue'
import { pluralise } from '@/lib/globalUtils'

// Composition
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'

// Components
import {
    Modal,
    Button,
    Stack,
    Container,
    Text,
    Checkbox,
    ProfileImage,
    Tooltip,
    TextInput,
    Scroller,
    EmptyState,
    ClickableRow,
    useMediaQuery,
} from '@opteo/components-next'

// Props
const props = defineProps({
    modelValue: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Emits
const emit = defineEmits(['update:modelValue'])

// Setup
const open = ref(false)
const { getAvatarUrl } = useBoringAvatar()
const { aboveMobile, above480 } = useMediaQuery()
const {
    linkingAccounts,
    teamMembers,
    allTeamMembersAssigned,
    someTeamMembersAssigned,
    assignedTeamMembers,
    searchedTeamMember,
    filteredTeamMembers,
    assignTeamMember,
    assignAllTeamMembers,
    updateLinkedAccountStatusWithTeamMembers,
    selectedUnlinkedAccountsCount,
    updatingLinkedAccounts,
    removeTeamMembersButton,
    addTeamMembersButton,
    removingTeamMembers,
    addingTeamMembers,
    assignTeamMembersToAccounts,
} = useLinkedAccounts()

// Functions
function closeModal() {
    open.value = false
}

// Watch
watch(props, async ({ modelValue }) => {
    open.value = modelValue
})

watch(open, async newVal => {
    emit('update:modelValue', newVal)
})
</script>

<style scoped lang="scss"></style>
