<template>
    <Stack ref="stackRef">
        <Select
            name="date-range"
            v-model="selectedConnection"
            :options="formattedAvailableConnections"
            placeholder="Select Email Address"
            @updated="onUpdate"
            :fullWidth="false"
            :maxWidth="164"
            :popoutMinWidth="200"
            :offset="[-1, 6]"
            buttonSize="small"
            :appendTo="stackEl"
            :loading="account.id?.includes('skeleton')"
            :skeletonWidth="116"
            :disabled="isUnlinkable(account)"
        >
        </Select>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { ref, computed, watch, PropType } from 'vue'

// Types
import { Account, Team } from '@opteo/types'

// Composition
import { Endpoint, authRequest } from '@/composition/api/useAPI'
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'

// Components
import { Select, Stack } from '@opteo/components-next'

// Props
const props = defineProps({
    account: {
        type: Object as PropType<Team.Account>,
        required: true,
    },
    availableConnections: {
        type: Array as PropType<
            {
                isCurrent: boolean
                connectionId: number
                loginCustomerId: string
                email: string
            }[]
        >,
        default: () => [],
        required: false,
    },
    accountId: {
        type: String as PropType<Account.ID>,
        required: false,
        default: '',
    },
})

// Setup
const { mutateLinkedAccounts, teamAccounts, isUnlinkable } = useLinkedAccounts()
const selectedConnection = ref(-1)

const initializeSelectedConnection = () => {
    if (props.availableConnections && Array.isArray(props.availableConnections)) {
        const current = props.availableConnections.find(item => item.isCurrent)
        selectedConnection.value = current?.connectionId ?? -1
    }
}
watch(() => props.availableConnections, initializeSelectedConnection, { immediate: true })

// Computed
const formattedAvailableConnections = computed(() => {
    // Check if props.availableConnections exists and is an array
    if (!props.availableConnections || !Array.isArray(props.availableConnections)) {
        return [] // Return empty array as a fallback
    }

    // Now it's safe to map over the array
    return props.availableConnections.map(connection => {
        return {
            label: connection.email,
            value: connection.connectionId,
        }
    })
})

// Functions
function onUpdate(value: number | string) {
    updateChangelogEmail(value as number)
}

const updateChangelogEmail = async (connectionId: number) => {
    const loginCustomerId = (props.availableConnections ?? []).find(
        c => c.connectionId === connectionId
    )?.loginCustomerId

    const updatedAccounts: Team.Account[] =
        teamAccounts.value?.accounts?.map(account => {
            if (account.accountId === props.accountId) {
                return {
                    ...account,
                    availableConnections: props.availableConnections.map(connection => {
                        const isCurrent = connection.connectionId === connectionId
                        return {
                            ...connection,
                            isCurrent,
                        }
                    }),
                }
            }

            return account
        }) ?? []

    mutateLinkedAccounts(() => {
        return {
            team: teamAccounts.value?.team!,
            accounts: updatedAccounts,
        }
    })

    /* 
        We are not awaiting this request because, we want to email selection to be instant
        even when the request is slowed downed by the db.transaction in the backend.
    */
    doAuthRequest(connectionId, loginCustomerId!)
}

async function doAuthRequest(connectionId: number, loginCustomerId: string) {
    await authRequest(Endpoint.SetAccountConnection, {
        body: {
            account_id: props.accountId,
            connection_id: connectionId,
            login_customer_id: loginCustomerId,
        },
    })
    // mutateLinkedAccounts()
}

// appendTo
const stackRef = ref<InstanceType<typeof Stack> | null>(null)
const stackEl = computed(() => {
    return stackRef.value?.$el ?? document.body
})
</script>

<style scoped lang="scss"></style>
