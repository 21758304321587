import { ref } from 'vue'
import { Routes } from '@/router/routes'
import { useRoute, useRouter } from 'vue-router'
import { noAuthRequest } from '../api/useAPI'
import { useAuth } from './useAuth'
import Cookies from 'universal-cookie'
import { Api, Team, Billing } from '@opteo/types'
import { pushToDatalayer } from '@/lib/datalayer'

const cookies = new Cookies()

export function useCreateAccount() {
    const { setCredentials } = useAuth()
    const route = useRoute()
    const router = useRouter()

    const name = ref('')
    const emailAddress = ref('')
    const password = ref('')
    const avatar = ref<Team.Avatar | ''>('')

    const ati = ref(cookies.get('ati'))
    const planId = ref(
        route.query.plan_id ?? cookies.get('chosen_plan_id') ?? Billing.StandardPlan.Basic
    )

    cookies.set('chosen_plan_id', planId.value, { path: '/' })

    const avatarRadioOptions = ref([
        { value: 'agency', label: '<b>Agency</b> (I manage Google Ads for my clients).' },
        { value: 'business', label: '<b>Business</b> (I manage Google Ads for my business).' },
    ])

    const loading = ref(false)
    const error = ref('')

    async function createAccount(formData: Record<string, any>) {
        error.value = ''
        try {
            loading.value = true

            const response = await noAuthRequest<Api.CreateAccount.Response>('/createAccount', {
                name: formData.name,
                emailAddress: formData.emailAddress,
                password: formData.password,
                ati: ati.value,
                planId: planId.value,
                accountCreationDomain: window.location.hostname,
                ...(window.Rewardful?.referral && { referral: window.Rewardful.referral }),
                ...(window.Rewardful?.coupon && { coupon: window.Rewardful.coupon.id }),
            })

            if (!response.success) {
                error.value = response.error
                throw new Error(error.value)
            }

            const { user_id, token } = response
            setCredentials(token, user_id)

            router.push({ name: Routes.AfterCreate, params: { id: user_id } })

            pushToDatalayer('gtm_created_account', {
                user_attributes: {
                    user_id,
                    email: formData.emailAddress,
                    role: 'admin',
                    first_name: (formData.name ?? '').split(' ')[0],
                    last_name: (formData.name ?? '').split(' ')[1],
                },
                team_attributes: {
                    plan_id: planId.value,
                },
            })

            return
        } catch (err: any) {
            const errorMessage = err.message || String(err) || 'An unknown error occurred'
            error.value = errorMessage
        } finally {
            loading.value = false
        }
    }

    return {
        name,
        emailAddress,
        password,
        avatar,
        planId,
        ati,
        avatarRadioOptions,
        createAccount,
        loading,
        error,
    }
}
