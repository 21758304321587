<template>
    <!-- Content -->
    <section>
        <!-- Header -->
        <StickyHeader :title="above480 ? 'Accounts' : ''">
            <template #titleSuffix>
                <Stack align="center" :gap="10">
                    <Tabs
                        :items="tabLinks"
                        :active="currentView"
                        @clicked="updateView"
                        :loading="teamAccountsLoading"
                        size="small"
                    />
                    <Transition name="fade-up-load">
                        <IndicatorTag
                            v-if="newUnlinkedAccounts && !teamAccountsLoading"
                            color="green"
                        >
                            New Unlinked
                        </IndicatorTag>
                    </Transition>
                </Stack>
            </template>
            <template #right>
                <Stack :maxWidth="188">
                    <!-- Search -->
                    <TextInput
                        v-if="currentView === 'linked' && above768"
                        name="searchLinkedAccounts"
                        v-model="linkedSearchQuery"
                        placeholder="Search"
                        autocomplete="off"
                        icon="Search"
                    />
                    <TextInput
                        v-if="currentView === 'unlinked' && above768"
                        name="searchUnlinkedAccounts"
                        v-model="unlinkedSearchQuery"
                        placeholder="Search"
                        autocomplete="off"
                        icon="Search"
                    />
                </Stack>
                <!-- Import MCC -->
                <Button
                    v-if="platform === Platform.Platform.GoogleAds"
                    color="secondary"
                    @click="connectGoogleAds"
                    size="small"
                >
                    <template #before>
                        <GoogleAdsLogo :size="12" />
                    </template>
                    Import <span v-if="above768">Account</span>
                </Button>

                <Button
                    v-if="platform === Platform.Platform.MicrosoftAds"
                    color="secondary"
                    @click="connectMicrosoftAds"
                    size="small"
                >
                    <template #before>
                        <MicrosoftAdsLogo :size="12" />
                    </template>
                    Import <span v-if="above768">Account</span>
                </Button>

                <Button
                    v-if="platform === Platform.Platform.MetaAds"
                    color="secondary"
                    @click="connectMetaAds"
                    size="small"
                >
                    <template #before>
                        <MetaAdsLogo :size="12" />
                    </template>
                    Import <span v-if="above768">Account</span>
                </Button>

                <Button
                    v-if="platform === Platform.Platform.LinkedInAds"
                    color="secondary"
                    @click="connectLinkedInAds"
                    size="small"
                >
                    <template #before>
                        <LinkedInAdsLogo :size="12" />
                    </template>
                    Import <span v-if="above768">Account</span>
                </Button>
            </template>
        </StickyHeader>
        <!-- Accounts -->
        <Stack direction="column" align="center">
            <LinkedAccountsTableLinked v-if="currentView === 'linked'" ref="linkedAccountsTable" />
            <LinkedAccountsTableUnlinked
                v-if="currentView === 'unlinked'"
                ref="unlinkedAccountsTable"
            />
        </Stack>
    </section>

    <!-- Linked BatchBar -->
    <BatchBar
        v-model="showLinkedBatchBar"
        label="Account"
        :bottomOffset="above1024 ? 0 : 80"
        :leftOffset="above1024 ? 81 : 0"
        @clearSelected="clearSelectedAccounts"
        :count="selectedLinkedAccountsCount"
    >
        <template #buttons>
            <Button
                ref="assignTeamMembersRef"
                @click="assignTeamModalOpen = true"
                color="batch-bar"
                iconBefore="User"
                :size="above768 ? 'medium' : 'small'"
            >
                Assign <span v-if="above768">Team Members</span>
            </Button>
            <Button
                ref="unlinkAccountsRef"
                color="red"
                @click="unlinkAccountsModalOpen = true"
                iconBefore="Unlink"
                :loading="updatingLinkedAccounts"
                :size="above768 ? 'medium' : 'small'"
            >
                Unlink
                <span v-if="above768">
                    {{ pluralise(selectedLinkedAccountsCount, 'Account', 'Accounts') }}
                </span>
            </Button>
        </template>
    </BatchBar>

    <!-- Unlinked BatchBar -->
    <BatchBar
        v-model="showUnlinkedBatchBar"
        label="Account"
        :bottomOffset="above1024 ? 0 : 80"
        :leftOffset="above1024 ? 81 : 0"
        :count="selectedUnlinkedAccountsCount"
        @clearSelected="clearSelectedAccounts"
    >
        <template #buttons>
            <Button
                ref="linkAccountsRef"
                color="green"
                @click="updateLinkedAccountStatus(true)"
                iconBefore="Link"
                :loading="updatingLinkedAccounts"
                :size="above768 ? 'medium' : 'small'"
            >
                Link
                <span v-if="above768">
                    {{ pluralise(selectedUnlinkedAccountsCount, 'Account', 'Accounts') }}
                </span>
            </Button>
        </template>
    </BatchBar>

    <!-- UnlinkAccounts Modal -->
    <UnlinkAccountsModal v-model="unlinkAccountsModalOpen" />

    <!-- PlanLimit Modal -->
    <PlanLimitModal v-model="planLimitWarning" />
</template>

<script setup lang="ts">
// Imports
import { computed, ref, watch } from 'vue'
import { pluralise } from '@/lib/globalUtils'

// Types
import { Platform } from '@opteo/types'

// Composition
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'

// Components
import {
    StickyHeader,
    Stack,
    Tabs,
    Button,
    GoogleAdsLogo,
    TextInput,
    BatchBar,
    MicrosoftAdsLogo,
    MetaAdsLogo,
    LinkedInAdsLogo,
    useMediaQuery,
} from '@opteo/components-next'
import UnlinkAccountsModal from '@/components/linkedAccounts/Modals/UnlinkAccountsModal.vue'
import PlanLimitModal from '@/components/linkedAccounts/Modals/PlanLimitModal.vue'
import IndicatorTag from '@/components/global/IndicatorTag.vue'
import LinkedAccountsTableLinked from '@/components/linkedAccounts/LinkedAccountsTableLinked.vue'
import LinkedAccountsTableUnlinked from '@/components/linkedAccounts/LinkedAccountsTableUnlinked.vue'

// Setup
const {
    newUnlinkedAccounts,
    currentView,
    linkingAccounts,
    updateView,
    connectGoogleAds,
    connectMicrosoftAds,
    connectMetaAds,
    connectLinkedInAds,
    tabLinks,
    searchedLinkedAccounts,
    searchedUnlinkedAccounts,
    someUnlinkedAccountsSelected,
    selectedUnlinkedAccountsCount,
    updateLinkedAccountStatus,
    updatingLinkedAccounts,
    someLinkedAccountsSelected,
    selectedLinkedAccountsCount,
    linkAccountsRef,
    unlinkAccountsRef,
    unlinkAccountsModalOpen,
    assignTeamModalOpen,
    planLimitWarning,
    assignTeamMembersRef,
    clearSelectedAccounts,
    platform,
    teamAccountsLoading,
} = useLinkedAccounts()
const linkedAccountsTable = ref()
const unlinkedAccountsTable = ref()
const { above480, above768, above1024 } = useMediaQuery()

// Computed
const linkedSearchQuery = computed({
    get: () => linkedAccountsTable.value?.tableRef?.searchQuery || '',
    set: value => {
        if (linkedAccountsTable.value?.tableRef) {
            linkedAccountsTable.value.tableRef.searchQuery = value
        }
    },
})

const unlinkedSearchQuery = computed({
    get: () => unlinkedAccountsTable.value?.tableRef?.searchQuery || '',
    set: value => {
        if (unlinkedAccountsTable.value?.tableRef) {
            unlinkedAccountsTable.value.tableRef.searchQuery = value
        }
    },
})

const showLinkedBatchBar = ref(false)
const showUnlinkedBatchBar = ref(false)

// Update the refs when conditions change
watch(
    () =>
        !linkingAccounts.value &&
        (someLinkedAccountsSelected.value || updatingLinkedAccounts.value),
    newValue => {
        showLinkedBatchBar.value = newValue
    },
    { immediate: true }
)

watch(
    () =>
        linkingAccounts.value &&
        (someUnlinkedAccountsSelected.value || updatingLinkedAccounts.value),
    newValue => {
        showUnlinkedBatchBar.value = newValue
    },
    { immediate: true }
)

// Watch
watch(updatingLinkedAccounts, async newValue => {
    if (!newValue) {
        searchedLinkedAccounts.value = ''
        searchedUnlinkedAccounts.value = ''
    }
})
</script>
