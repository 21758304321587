<template>
    <Stack :gap="0">
        <Stack
            direction="column"
            :gap="0"
            width="50%"
            align="start"
            :padding="above480 ? [17, 20, 15, 20] : [14, 14, 12, 14]"
            borderRight
        >
            <!-- Headline -->
            <Stack v-if="teamAccountsLoading" :height="20">
                <Skeleton :width="136" :height="14" />
            </Stack>
            <Text v-else size="headline-five">
                <b><Number :value="linkedAccountsCount" /></b> <span v-if="above640">Linked</span>
                {{ linkedAccountsCount === 1 ? 'Account' : 'Accounts' }}
            </Text>
            <Spacer :height="10" />
            <!-- ProgressBar -->
            <Skeleton v-if="teamAccountsLoading" width="100%" :height="6" color="secondary" />
            <ProgressBar
                v-else
                :progress="linkedAccountProgress"
                :projected="
                    linkingAccounts ? projectedLinkedAccountProgress : linkedAccountProgress
                "
                :color="linkedAccountProgressColor"
                :animateOnLoad="false"
            />
            <Spacer :height="8" />
            <!-- Subhead -->
            <Stack v-if="teamAccountsLoading" :height="18">
                <Skeleton :width="120" :height="8" color="secondary" />
            </Stack>
            <Text v-else color="foreground-minus-two" size="body-extra-small">
                <span v-if="accountsCountRemaining === Infinity">Unlimited</span>
                <Number
                    v-else
                    :value="
                        linkingAccounts ? projectedAccountsCountRemaining : accountsCountRemaining
                    "
                />
                <span v-if="above640">
                    {{ accountsCountRemaining === 1 ? ' account' : ' accounts' }}
                </span>
                remaining
            </Text>
        </Stack>
        <Stack
            direction="column"
            :gap="0"
            width="50%"
            align="start"
            :padding="above480 ? [17, 20, 15, 20] : [14, 14, 12, 14]"
        >
            <!-- Headline -->
            <Stack v-if="teamAccountsLoading" :height="20">
                <Skeleton :width="156" :height="14" />
            </Stack>
            <Text v-else size="headline-five">
                <Money currency="USD" :value="linkedAccounts30dSpend" />
                <span v-if="above640"> Monthly</span> Spend
            </Text>
            <Spacer :height="10" />
            <!-- ProgressBar -->
            <Skeleton v-if="teamAccountsLoading" width="100%" :height="6" color="secondary" />
            <ProgressBar
                v-else
                :progress="spendProgress"
                :projected="linkingAccounts ? projectedSpendProgress : spendProgress"
                :color="spendProgressColor"
                :animateOnLoad="false"
            />
            <Spacer :height="8" />
            <!-- Subhead -->
            <Stack v-if="teamAccountsLoading" :height="18">
                <Skeleton :width="138" :height="8" color="secondary" />
            </Stack>
            <Text v-else color="foreground-minus-two" size="body-extra-small">
                <span v-if="accounts30dSpendRemaining === Infinity">Unlimited</span>
                <Money
                    v-else
                    currency="USD"
                    :value="
                        linkingAccounts
                            ? projectedAccounts30dSpendRemaining
                            : accounts30dSpendRemaining
                    "
                />
                <span v-if="above640"> spend</span> remaining
            </Text>
        </Stack>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { computed } from 'vue'

// Components
import {
    Text,
    Money,
    Spacer,
    Number,
    ProgressBar,
    Stack,
    Skeleton,
    useMediaQuery,
} from '@opteo/components-next'

// Composition
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'

// Setup
const {
    linkedAccountsCount,
    accountsCountRemaining,
    linkedAccounts30dSpend,
    accounts30dSpendRemaining,
    currentPlan,
    projectedLinkedAccountsCount,
    projectedLinkedAccounts30dSpend,
    linkingAccounts,
    teamAccountsLoading,
} = useLinkedAccounts()
const { above480, above640 } = useMediaQuery()

// Computed
const linkedAccountProgress = computed(() => {
    if (!currentPlan.value) return 0

    if (!currentPlan.value?.account_limit) return 0
    if (!linkedAccountsCount.value) return 0

    return currentPlan.value?.account_limit &&
        linkedAccountsCount.value > currentPlan.value?.account_limit
        ? 1
        : linkedAccountsCount.value / currentPlan.value?.account_limit
})

const projectedAccountsCountRemaining = computed(() => {
    if (projectedLinkedAccountsCount.value == null) return 0
    if (currentPlan.value?.account_limit == null) return Infinity

    return currentPlan.value?.account_limit &&
        projectedLinkedAccountsCount.value > currentPlan.value?.account_limit
        ? 0
        : currentPlan.value?.account_limit - projectedLinkedAccountsCount.value
})

const projectedLinkedAccountProgress = computed(() => {
    if (!currentPlan.value?.account_limit || !projectedLinkedAccountsCount.value) return 0

    return currentPlan.value?.account_limit &&
        projectedLinkedAccountsCount.value > currentPlan.value?.account_limit
        ? 1
        : projectedLinkedAccountsCount.value / currentPlan.value?.account_limit
})

const spendProgress = computed(() => {
    if (!currentPlan.value) return 0
    if (currentPlan.value?.spend_limit === null || currentPlan.value?.spend_limit === undefined)
        return 0
    if (currentPlan.value?.spend_limit === 0) return 0
    if (!linkedAccounts30dSpend.value) return 0

    return currentPlan.value?.spend_limit &&
        linkedAccounts30dSpend.value > currentPlan.value?.spend_limit
        ? 1
        : linkedAccounts30dSpend.value / currentPlan.value?.spend_limit
})

const projectedAccounts30dSpendRemaining = computed(() => {
    if (projectedLinkedAccounts30dSpend.value == null) return 0
    if (currentPlan.value?.spend_limit == null || currentPlan.value?.spend_limit == 0)
        return Infinity

    return currentPlan.value?.spend_limit &&
        projectedLinkedAccounts30dSpend.value > currentPlan.value?.spend_limit
        ? 0
        : currentPlan.value?.spend_limit - projectedLinkedAccounts30dSpend.value
})

const projectedSpendProgress = computed(() => {
    if (!currentPlan.value?.spend_limit || !projectedLinkedAccounts30dSpend.value) return 0

    return currentPlan.value?.spend_limit &&
        projectedLinkedAccounts30dSpend.value > currentPlan.value?.spend_limit
        ? 1
        : projectedLinkedAccounts30dSpend.value / currentPlan.value?.spend_limit
})

const linkedAccountProgressColor = computed(() =>
    determineColor('count', teamAccountsLoading.value)
)

const spendProgressColor = computed(() => determineColor('spend', teamAccountsLoading.value))

// Functions
function determineColor(progressBarType: 'spend' | 'count', loading: boolean) {
    const limit =
        progressBarType === 'spend'
            ? currentPlan.value?.spend_limit
            : currentPlan.value?.account_limit

    const currentValue =
        progressBarType === 'spend'
            ? projectedLinkedAccounts30dSpend.value
            : projectedLinkedAccountsCount.value

    if (loading) return 'primary'

    if (limit === null || limit === 0) return 'green'

    if (currentValue > (limit ?? 0)) return 'red'

    return 'primary'
}
</script>

<style scoped lang="scss"></style>
