<template>
    <Container
        v-if="loading"
        :borderRadius="999"
        :padding="[9, 13, 9, 12]"
        :color="['input', 'background-plus-one-hover']"
        shadow="none"
        :outline="false"
    >
        <Stack :gap="9">
            <Skeleton :width="16" :height="16" />
            <Stack :height="15" align="center">
                <Skeleton :width="66" :height="10" />
            </Stack>
        </Stack>
    </Container>
    <Container
        v-else
        :borderRadius="999"
        :padding="name || isUserDefined ? [9, 13, 9, 12] : [9, 12]"
        :color="containerColor"
        shadow="none"
        :outline="false"
    >
        <Stack :gap="9">
            <Dot v-if="mode === 'account'" :size="16" :color="color" />
            <ProfileImage
                v-else-if="isUserDefined"
                :size="16"
                :imageUrl="userAttributes?.imageUrl"
                :boringAvatar="userAttributes?.boringAvatarUrl"
            />
            <Dot v-else-if="!isUserDefined" :size="16" color="gray" />
            <Text v-if="name" as="h6" size="headline-seven" :color="textColor" :wrap="false">
                {{ name }}
            </Text>
            <Text
                v-else-if="!name && isUserDefined"
                as="h6"
                size="headline-seven"
                :color="textColor"
                :wrap="false"
            >
                {{ userAttributes?.userName }}
            </Text>
        </Stack>
    </Container>
</template>

<script setup lang="ts">
// Imports
import { PropType, computed } from 'vue'
import { AccountGroup as OptAccountGroup, User } from '@opteo/types'

// Composition
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import { useTeam } from '@/composition/user/useTeam'

// Components
import { Container, Stack, Dot, ProfileImage, Text, Skeleton } from '@opteo/components-next'

// Props
const props = defineProps({
    id: {
        type: [Number, null] as PropType<OptAccountGroup.ID | User.ID | null>,
        required: true,
    },
    mode: {
        type: String as PropType<'account' | 'user'>,
        required: false,
        default: 'account',
    },
    color: {
        type: String as PropType<string>,
        required: false,
        default: 'gray',
    },
    name: {
        type: String as PropType<string>,
        required: false,
    },
    loading: {
        type: Boolean as PropType<boolean>,
        required: false,
    },
})

// Setup
const { getAvatarUrl } = useBoringAvatar()
const { team } = useTeam()

// Functions
function findTeamMember(userId: number) {
    if (!team.value) {
        return
    } else {
        return team.value.find(user => user.user_id === userId)
    }
}

// Computed
const isUngroupedOrUnassigned = computed(() => {
    return props.id === null
})

const userAttributes = computed(() => {
    if (props.mode === 'user' && props.id) {
        const user = findTeamMember(props.id)

        return {
            boringAvatarUrl: getAvatarUrl(user?.user_id),
            imageUrl: user?.avatar_filename,
            userName: user?.name,
        }
    }
    return undefined
})

const isUserDefined = computed(() => {
    return !!userAttributes.value
})

const containerColor = computed(() => {
    if (props.color === 'gray' || props.mode === 'user') {
        return ['input', 'background-plus-one-hover']
    } else {
        return `${props.color}-translucent`
    }
})
const textColor = computed(() => {
    if (props.mode === 'user' && isUngroupedOrUnassigned.value === true) {
        return 'foreground-minus-one'
    } else if (props.mode === 'user') {
        return 'foreground'
    } else if (props.color === 'gray') {
        return 'foreground-minus-one'
    } else {
        return [props.color, props.color]
    }
})
</script>

<style scoped lang="scss"></style>
