<template>
    <Modal v-model="open" title="Plan Limit Reached" :width="400" @close="closeModal">
        <template #content>
            <Container
                color="background-plus-one"
                :borderRadius="20"
                :gap="0"
                width="100%"
                :padding="[18, 22]"
                hideOverflow
            >
                <Stack direction="column" :gap="20">
                    <Text size="body-small">
                        Linking this account would exceed your plan limit. You are currently
                        subscribed to the <b>{{ currentPlan?.name }}</b> plan, which includes
                        <b><Money currency="USD" :value="currentPlan?.spend_limit ?? 0" /></b> spend
                        per month and <b>{{ currentPlan?.account_limit }}</b> linked accounts.
                    </Text>
                    <Text size="body-small">
                        To link this account, head to
                        <b>Billing and Invoices</b> and upgrade your pricing plan (accounting for
                        the space required). If you have any questions, please don't hesitate to
                        message our support team.
                    </Text>
                </Stack>
            </Container>
        </template>
        <template #buttons>
            <Button color="secondary" @click="clearSelectedAccounts">Clear Selected</Button>
            <Button ref="updateTeamSettingsButton" @click="goToBilling" iconAfter="ArrowUpRight">
                Billing and Invoices
            </Button>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, watch } from 'vue'

// Composition
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'

// Components
import { Modal, Button, Stack, Container, Text, Money } from '@opteo/components-next'

// Props
const props = defineProps({
    modelValue: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Emits
const emit = defineEmits(['update:modelValue'])

// Setup
const open = ref(false)
const { currentPlan, clearSelectedAccounts, goToBilling } = useLinkedAccounts()

// Functions
function closeModal() {
    open.value = false
}

// Watch
watch(props, async ({ modelValue }) => {
    open.value = modelValue
})

watch(open, async newVal => {
    emit('update:modelValue', newVal)
})
</script>

<style scoped lang="scss"></style>
