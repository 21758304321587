<template>
    <Stack :gap="14">
        <Skeleton v-if="account.id?.includes('skeleton')" :width="28" :height="28" />
        <Avatar v-else :color="account?.name.color" :platform="account?.platform">
            {{ account?.initials }}
        </Avatar>
        <Stack v-if="account.id?.includes('skeleton')" :height="15" align="center">
            <Skeleton :width="Math.random() * 20 + 80" :height="12" />
        </Stack>
        <Text v-else as="span" size="button" :color="account?.name.color" :wrap="false" noSelect>
            {{ account?.name.label }}
        </Text>
        <Tooltip
            v-if="isUnlinkableAccount || recentlyAddedAccount"
            :content="
                isUnlinkableAccount
                    ? 'Personal accounts are not supported by Opteo'
                    : 'Recently added'
            "
        >
            <WarnIcon color="gray" small />
        </Tooltip>
    </Stack>
</template>

<script lang="ts" setup>
// Imports
import { computed } from 'vue'
import isBefore from 'date-fns/isBefore'

// Types
import { Team } from '@opteo/types'

// Composition
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'

// Components
import { Stack, Avatar, Text, WarnIcon, Tooltip, Skeleton } from '@opteo/components-next'

// Props
const props = defineProps<{ account: Team.Account; linked: boolean }>()

// Setup
const { unlinkedVisitedUnmountTS } = useLinkedAccounts()

// Computed
const recentlyAddedAccount = computed(
    () =>
        !props.linked &&
        !!isBefore(unlinkedVisitedUnmountTS.value, new Date(props.account.connectedToOpteoTs))
)
const isUnlinkableAccount = computed(() => !props.linked && props.account.isUnlinkable)
</script>

<style scoped lang="scss"></style>
