import { Account, Api, User } from '@opteo/types'
import { authRequest, Endpoint, useAPI } from '@/composition/api/useAPI'
import { useAccountList } from '@/composition/user/useAccountList'

const ungroupedGroup = {
    accountGroupId: null,
    name: 'Ungrouped',
    colour: 'gray',
} as const

const unassignedManager = {
    user_id: null,
    name: 'Unassigned',
} as const

export function useAccountGroups() {
    const { mutateDomainList } = useAccountList()

    const { data: accountGroups, mutate: mutateAccountGroups } =
        useAPI<Api.GetAccountGroups.Response>(Endpoint.GetAccountGroups)

    async function createAccountGroup({
        name,
        colour,
        accountIds,
    }: {
        name: string
        colour: string
        accountIds?: Account.ID[]
    }) {
        await authRequest(Endpoint.CreateAccountGroup, {
            body: {
                name,
                colour,
                accountIds: accountIds ?? [],
            } satisfies Api.CreateAccountGroup.Request,
        })

        mutateDomainList()
        mutateAccountGroups()
    }

    async function assignAccountToGroup({
        accountId,
        accountGroupId,
    }: {
        accountId: Account.ID
        accountGroupId: number | null
    }) {
        mutateDomainList(accountId, { accountGroupId })

        await authRequest(Endpoint.AssignAccountToGroup, {
            body: {
                accountGroupId,
                accountId,
            } satisfies Api.AssignAccountToGroup.Request,
        })
    }

    async function assignAccountToManager({
        accountId,
        managerUserId,
    }: {
        accountId: Account.ID
        managerUserId: User.ID | null
    }) {
        mutateDomainList(accountId, { managerUserId })

        await authRequest(Endpoint.AssignAccountToManager, {
            body: {
                managerUserId,
                accountId,
            } satisfies Api.AssignAccountToManager.Request,
        })
    }

    return {
        accountGroups,
        ungroupedGroup,
        unassignedManager,
        createAccountGroup,
        assignAccountToGroup,
        assignAccountToManager,
    }
}
