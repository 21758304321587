import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { delay } from '@opteo/promise'
import { Account } from '@opteo/types'
import { Routes } from './routes'
import {
    checkLoggedIn,
    checkAuthValid,
    checkIfOverLimit,
    trackUserPageLoad,
} from '@/composition/auth/useAuth'

import { authRequest, Endpoint } from '@/composition/api/useAPI'

// Misc
import Login from '@/pages/login.vue'
import ResetPassword from '@/pages/reset.vue'
import ResetPasswordLink from '@/pages/resetlink.vue'
import PageNotFound from '@/pages/404.vue'
import EmailLink from '@/pages/link/[id].vue'
import InviteWelcome from '@/pages/InviteWelcome.vue'
import Thanks from '@/pages/thanks.vue'
import SharedChat from '@/pages/sharedchat/[id].vue'

// Onboarding
import CreateAccount from '@/pages/createaccount.vue'
import AfterCreate from '@/pages/aftercreate.vue'
import ConnectGoogleAds from '@/pages/user/onboarding/connectgoogleads.vue'

// User
import User from '@/pages/user/index.vue'
import Accounts from '@/pages/user/accounts/index.vue'
import Chat from '@/pages/user/chat/index.vue'
import Reports from '@/pages/user/reports/index.vue'
import Basecamp from '@/pages/user/basecamp/index.vue'
import Alerts from '@/pages/user/alerts/index.vue'
import Alert from '@/pages/user/alerts/[id].vue'
import LinkedAccounts from '@/pages/user/linkedaccounts/index.vue'
import LinkedAccountsPlatform from '@/pages/user/linkedaccounts/platform.vue'
import LinkedAccountsPage from '@/pages/user/linkedaccounts/content.vue'
import NewPaymentMethod from '@/pages/user/onboarding/newpaymentmethod.vue'
import Thankyou from '@/pages/user/onboarding/thankyou.vue'

// User Settings
import UserSettingsIndex from '@/pages/user/settings/index.vue'
import UserSettings from '@/pages/user/settings/settings.vue'
import ImpactStatistics from '@/pages/user/settings/impactstatistics.vue'
import Billing from '@/pages/user/settings/billing.vue'

// Account
import AccountIndex from '@/pages/user/account/index.vue'
import Notes from '@/pages/user/account/notes.vue'
import DomainSettings from '@/pages/user/account/settings.vue'
import Setup from '@/pages/user/account/setup.vue'
import Targets from '@/pages/user/account/targets.vue'

// Improvements
import Improvements from '@/pages/user/account/improvements/index.vue'
import ActiveImprovements from '@/pages/user/account/improvements/active/index.vue'
import Improvement from '@/pages/user/account/improvements/active/[id].vue'
import CompletedImprovements from '@/pages/user/account/improvements/completed/index.vue'
import ImprovementCompleted from '@/pages/user/account/improvements/completed/[id].vue'
import DismissedImprovements from '@/pages/user/account/improvements/dismissed/index.vue'
import ImprovementDismissed from '@/pages/user/account/improvements/dismissed/[id].vue'

// Issues
import Issues from '@/pages/user/account/issues/index.vue'

// Performance
import Performance from '@/pages/user/account/performance/index.vue'
import PerformanceGraphs from '@/pages/user/account/performance/graphs.vue'
import PerformanceScorecard from '@/pages/user/account/performance/scorecard/index.vue'
import PerformanceScorecardList from '@/pages/user/account/performance/scorecard/list.vue'
import PerformanceScorecardCreate from '@/pages/user/account/performance/scorecard/create.vue'
import PerformanceScorecardSingle from '@/pages/user/account/performance/scorecard/scorecardSingle.vue'
import PerformanceSegments from '@/pages/user/account/performance/segments.vue'
import PerformanceTrends from '@/pages/user/account/performance/trends.vue'

// Reports
// import Reports from '@/pages/user/account/reports/index.vue'
// import ReportsActive from '@/pages/user/account/reports/active.vue'
// import ReportsArchive from '@/pages/user/account/reports/archive.vue'
// import Report from '@/pages/user/account/reports/report.vue'
// import Slides from '@/pages/user/account/reports/slides.vue'
// import ReportDownload from '@/pages/user/account/reports/download.vue'
// import ReportCreate from '@/pages/user/account/reports/create.vue'
// import ReportUpdate from '@/pages/user/account/reports/update.vue'

// Tools
import Toolkit from '@/pages/user/account/toolkit/index.vue'
import ToolkitNGramTool from '@/pages/user/account/toolkit/ngram/index.vue'
import ToolkitNgramToolFlow from '@/pages/user/account/toolkit/ngram/flow.vue'
import ToolkitKeywordFinder from '@/pages/user/account/toolkit/keywordfinder/index.vue'
import ToolkitAccountManager from '@/pages/user/account/toolkit/accountmanager/index.vue'
import ToolkitProductManager from '@/pages/user/account/toolkit/productmanager/index.vue'

// Shareable links
import ShareableSccorecard from '@/pages/shareable/shareableScorecard.vue'

// PDF rendering pages
import ScorecardPdf from '@/pages/pdf/scorecardPdf.vue'

const ImprovementRoutes: RouteRecordRaw = {
    path: 'improvements',
    name: Routes.Improvements,
    component: Improvements,
    redirect: { name: Routes.ImprovementsActive },
    children: [
        {
            name: Routes.ImprovementsActive,
            path: 'active',
            component: ActiveImprovements,
            children: [
                {
                    name: Routes.Improvement,
                    path: ':improvementId',
                    component: Improvement,
                },
            ],
        },
        {
            name: Routes.ImprovementsCompleted,
            path: 'completed',
            component: CompletedImprovements,
            children: [
                {
                    name: Routes.CompletedImprovement,
                    path: ':improvementId',
                    component: ImprovementCompleted,
                },
            ],
        },
        {
            name: Routes.ImprovementsDismissed,
            path: 'dismissed',
            component: DismissedImprovements,
            children: [
                {
                    name: Routes.DismissedImprovement,
                    path: ':improvementId',
                    component: ImprovementDismissed,
                },
            ],
        },
    ],
}

const IssuesRoutes: RouteRecordRaw = {
    path: 'isues',
    name: Routes.Issues,
    component: Issues,
}

const TargetsRoutes: RouteRecordRaw = {
    path: 'targets',
    name: Routes.Targets,
    component: Targets,
}

const PerformanceRoutes: RouteRecordRaw = {
    path: 'performance',
    name: Routes.Performance,
    component: Performance,
    redirect: { name: Routes.PerformanceGraphs },
    children: [
        {
            name: Routes.PerformanceGraphs,
            path: 'graphs',
            component: PerformanceGraphs,
        },
        {
            name: Routes.PerformanceScorecard,
            path: 'scorecard',
            component: PerformanceScorecard,
            redirect: { name: Routes.PerformanceScorecardList },
            children: [
                {
                    name: Routes.PerformanceScorecardList,
                    path: 'list',
                    component: PerformanceScorecardList,
                },
                {
                    name: Routes.PerformanceScorecardSingle,
                    path: ':scorecardId',
                    component: PerformanceScorecardSingle,
                },
                {
                    name: Routes.PerformanceScorecardCreate,
                    path: 'list',
                    component: PerformanceScorecardCreate,
                },
            ],
        },
        {
            name: Routes.PerformanceSegments,
            path: 'segments',
            component: PerformanceSegments,
        },
        {
            name: Routes.PerformanceTable,
            path: 'trends',
            component: PerformanceTrends,
        },
    ],
}

// const ReportsRoutes: RouteRecordRaw = {
//     path: 'reports',
//     name: Routes.Reports,
//     component: Reports,
//     redirect: { name: Routes.ReportsActive },
//     children: [
//         {
//             name: Routes.ReportsActive,
//             path: 'active',
//             component: ReportsActive,
//             children: [
//                 {
//                     name: Routes.ReportCreate,
//                     path: 'create',
//                     component: ReportCreate,
//                 },
//                 {
//                     name: Routes.ReportActive,
//                     path: ':reportId',
//                     component: Report,
//                     redirect: { name: Routes.ReportSlides },
//                     children: [
//                         {
//                             name: Routes.ReportSlides,
//                             path: 'slides',
//                             component: Slides,
//                         },
//                         {
//                             name: Routes.ReportDownload,
//                             path: 'download',
//                             component: ReportDownload,
//                         },
//                         {
//                             name: Routes.ReportUpdate,
//                             path: 'update',
//                             component: ReportUpdate,
//                         },
//                     ],
//                 },
//             ],
//         },
//         {
//             name: Routes.ReportsArchive,
//             path: 'archive',
//             component: ReportsArchive,
//             children: [
//                 {
//                     name: Routes.ReportArchive,
//                     path: ':reportId',
//                     component: Report,
//                     redirect: { name: Routes.ReportSlidesArchive },
//                     children: [
//                         {
//                             name: Routes.ReportSlidesArchive,
//                             path: 'slides',
//                             component: Slides,
//                         },
//                         {
//                             name: Routes.ReportDownloadArchive,
//                             path: 'download',
//                             component: ReportDownload,
//                         },
//                     ],
//                 },
//             ],
//         },
//     ],
// }

const ReportsRoutes: RouteRecordRaw = {
    path: 'reports',
    name: Routes.Reports,
    component: Reports,
}

const BasecampRoutes: RouteRecordRaw = {
    path: 'basecamp',
    name: Routes.Basecamp,
    component: Basecamp,
}

const ToolkitRoutes: RouteRecordRaw = {
    path: 'toolkit',
    name: Routes.Toolkit,
    component: Toolkit,
    redirect: { name: Routes.ToolkitNGramFinder },
    children: [
        {
            name: Routes.ToolkitNGramFinder,
            path: 'ngram',
            component: ToolkitNGramTool,
            redirect: { name: Routes.ToolkitNGramFinderSearch },
            children: [
                {
                    name: Routes.ToolkitNGramFinderSearch,
                    path: 'search-campaigns',
                    redirect: { name: Routes.ToolkitNGramFinderSearchSelect },
                    children: [
                        {
                            name: Routes.ToolkitNGramFinderSearchSelect,
                            path: 'select',
                            component: ToolkitNgramToolFlow,
                        },
                        {
                            name: Routes.ToolkitNGramFinderSearchConfirm,
                            path: 'confirm',
                            component: ToolkitNgramToolFlow,
                        },
                    ],
                },
                {
                    name: Routes.ToolkitNGramFinderPmax,
                    path: 'performance-max',
                    redirect: { name: Routes.ToolkitNGramFinderPmaxSelect },
                    children: [
                        {
                            name: Routes.ToolkitNGramFinderPmaxSelect,
                            path: 'select',
                            component: ToolkitNgramToolFlow,
                        },
                        {
                            name: Routes.ToolkitNGramFinderPmaxConfirm,
                            path: 'confirm',
                            component: ToolkitNgramToolFlow,
                        },
                    ],
                },
                {
                    name: Routes.ToolkitNGramFinderShopping,
                    path: 'shopping-campaigns',
                    redirect: { name: Routes.ToolkitNGramFinderShoppingSelect },
                    children: [
                        {
                            name: Routes.ToolkitNGramFinderShoppingSelect,
                            path: 'select',
                            component: ToolkitNgramToolFlow,
                        },
                        {
                            name: Routes.ToolkitNGramFinderShoppingConfirm,
                            path: 'confirm',
                            component: ToolkitNgramToolFlow,
                        },
                    ],
                },
            ],
        },
        {
            name: Routes.ToolkitKeywordFinder,
            path: 'keyword',
            component: ToolkitKeywordFinder,
        },
        {
            name: Routes.ToolkitAccountManager,
            path: 'account',
            component: ToolkitAccountManager,
        },
        {
            name: Routes.ToolkitProductManager,
            path: 'product',
            component: ToolkitProductManager,
        },
    ],
}

const AccountRoutes: RouteRecordRaw = {
    name: Routes.Account,
    path: 'account/:accountId',
    component: AccountIndex,
    redirect: { name: Routes.ImprovementsActive },
    children: [
        ImprovementRoutes,
        IssuesRoutes,
        PerformanceRoutes,
        // ReportsRoutes,
        TargetsRoutes,
        ToolkitRoutes,
        {
            name: Routes.DomainSettings,
            path: 'settings/:scroll_position?',
            component: DomainSettings,
        },
        {
            name: Routes.Notes,
            path: 'notes',
            component: Notes,
        },
        {
            name: Routes.Setup,
            path: 'setup',
            component: Setup,
        },
    ],
    beforeEnter: (to, from, next) => {
        checkIfOverLimit(to, from, next)
    },
}

const AlertsRoutes: RouteRecordRaw = {
    name: 'alerts',
    path: Routes.Alerts,
    component: Alerts,
    children: [
        {
            name: Routes.Alert,
            path: ':alertId',
            component: Alert,
        },
    ],
    beforeEnter: (to, from, next) => {
        checkIfOverLimit(to, from, next)
    },
}

const UserRoutes: RouteRecordRaw[] = [
    AccountRoutes,
    AlertsRoutes,
    ReportsRoutes,
    BasecampRoutes,
    // Redirect old domain base URLs to /account
    // This can be removed eventually and is an extra backup to Netlify redirects
    {
        path: 'domain/:domainId/:catchAll(.*)',
        redirect: to => {
            const newPath = to.path.replace('domain', 'account')
            return newPath
        },
    },
    {
        path: 'domainlist', // Redirect old domain base URLs to /account. Useful for old links, eg from sent emails
        redirect: { name: Routes.AccountCentre },
    },
    {
        name: Routes.AccountCentre,
        path: 'accounts',
        component: Accounts,
    },
    {
        name: Routes.AccountCentreInit,
        path: 'accounts/init',
        component: Accounts,
    },
    {
        name: Routes.Chat,
        path: 'chat/:accountId?',
        component: Chat,
    },
    {
        name: Routes.LinkedAccountsPlatform,
        path: '', // No path here. This nested route exists only to provide a :key="platform" to the router-view so that the contents are re-rendered when the route changes
        component: LinkedAccountsPlatform,
        redirect: { name: Routes.LinkedAccountsGoogle },
        children: [
            {
                name: Routes.LinkedAccounts,
                path: 'linkedaccounts',
                component: LinkedAccounts,
                redirect: { name: Routes.LinkedAccountsGoogle },
                children: [
                    {
                        name: Routes.LinkedAccountsGoogle,
                        path: 'google',
                        component: LinkedAccountsPage,
                    },
                    {
                        name: Routes.LinkedAccountsMicrosoft,
                        path: 'microsoft',
                        component: LinkedAccountsPage,
                    },
                    {
                        name: Routes.LinkedAccountsMeta,
                        path: 'meta',
                        component: LinkedAccountsPage,
                    },
                    {
                        name: Routes.LinkedAccountsLinkedIn,
                        path: 'linkedin',
                        component: LinkedAccountsPage,
                    },
                ],
            },
        ],
    },
    {
        name: Routes.UserSettingsIndex,
        path: '',
        component: UserSettingsIndex,
        redirect: { name: Routes.UserSettings },
        children: [
            {
                name: Routes.UserSettings,
                path: 'settings',
                component: UserSettings,
            },
            {
                name: Routes.ImpactStatistics,
                path: 'impactstatistics',
                component: ImpactStatistics,
            },
            {
                name: Routes.Billing,
                path: 'billing',
                component: Billing,
            },
        ],
    },
    {
        name: Routes.ConnectGoogleAds,
        path: 'connectgoogleads/:error?',
        component: ConnectGoogleAds,
    },
    {
        name: Routes.NewPaymentMethod,
        path: 'newpaymentmethod',
        component: NewPaymentMethod,
    },
    {
        name: Routes.Thankyou,
        path: 'thankyou/:reason?',
        component: Thankyou,
    },
]

const routes: Partial<RouteRecordRaw>[] = [
    {
        name: Routes.Root,
        path: '/',
        beforeEnter: (to, from, next) => {
            checkLoggedIn(to, from, next)
        },
    },
    {
        path: '/login',
        name: Routes.Login,
        component: Login,
        beforeEnter: (to, from, next) => {
            checkLoggedIn(to, from, next)
        },
    },
    {
        path: '/createaccount',
        name: Routes.CreateAccount,
        component: CreateAccount,
    },
    {
        path: '/aftercreate',
        name: Routes.AfterCreate,
        component: AfterCreate,
    },
    {
        path: '/reset',
        name: Routes.ResetPassword,
        component: ResetPassword,
    },
    {
        path: '/invitewelcome/:hash',
        name: Routes.InviteWelcome,
        component: InviteWelcome,
    },
    {
        path: '/resetlink/:email/:token', // Depends on rule defined in /public/_redirects
        name: Routes.ResetPasswordLink,
        component: ResetPasswordLink,
    },
    {
        path: `/user`,
        beforeEnter: (to, from, next) => {
            checkLoggedIn(to, from, next)
        },
    },
    {
        path: '/user/:id',
        name: Routes.User,
        component: User,
        beforeEnter: (to, from, next) => {
            checkAuthValid(to, from, next)
        },
        children: UserRoutes,
    },
    {
        name: Routes.Thanks,
        path: '/thanks',
        component: Thanks,
    },
    {
        name: Routes.EmailLink,
        path: '/link/:id',
        component: EmailLink,
    },
    {
        name: Routes.ShareableLinkScorecard,
        path: '/scorecard/:scorecardLinkId',
        component: ShareableSccorecard,
    },
    {
        name: Routes.PdfScorecard,
        path: '/pdf/scorecard',
        component: ScorecardPdf,
    },
    {
        name: Routes.PageNotFound,
        path: '/:catchAll(.*)',
        component: PageNotFound,
    },
    {
        name: Routes.SharedChat,
        path: '/c/:chatUuid',
        component: SharedChat,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes as RouteRecordRaw[],
    async scrollBehavior(to, from, savedPosition) {
        /*
            Here we can control the scroll position after changing route.
            Here what we have so far:
            - When pressing "back" or "forward", savedPostition handles restoring scrollPos automatically
            - When doing a transition defined in transitionsNeedingSavedScrollPos, we'll restore the scrollPos from lastScrollPositionsPerRoute
            - Else scroll to top
        */

        const transitionsNeedingSavedScrollPos = [
            [Routes.Improvement, Routes.ImprovementsActive],
            [Routes.CompletedImprovement, Routes.ImprovementsCompleted],
            [Routes.DismissedImprovement, Routes.ImprovementsDismissed],
        ]

        if (savedPosition) {
            return savedPosition
        }

        if (
            transitionsNeedingSavedScrollPos.find(([fromName, toName]) => {
                return fromName === from.name && toName === to.name
            })
        ) {
            return { top: lastScrollPositionsPerRoute[to.name as string] }
        }

        return { top: 0 }
    },
})

/* Save the scroll position to this static object before changing each route */
const lastScrollPositionsPerRoute: Record<string, number> = {}
router.beforeEach((to, from) => {
    if (!from.name) {
        return
    }

    const scrollPos = document.documentElement.scrollTop || document.body.scrollTop

    lastScrollPositionsPerRoute[from.name as string] = scrollPos

    trackUserPageLoad(to).catch(err => {
        console.error(err)
    })
})

// Rewrite domainId to accountId for people coming in from very old links
router.beforeEach(async (to, from) => {
    const accountIdParam = to.params.accountId
    if (+accountIdParam > 0 && to.name) {
        // If it' a number...
        try {
            const accountId = await authRequest<Account.ID>(Endpoint.GetAccountIdFromDomainId, {
                body: {
                    domain_id: +accountIdParam,
                },
            })

            return {
                name: to.name as string,
                params: {
                    ...to.params,
                    accountId,
                },
            }
        } catch (e) {
            console.error(e)
            console.log('Could not rewrite domainId to accountId, redirecting to account centre...')

            return {
                name: Routes.AccountCentre,
                params: {
                    ...to.params,
                },
            }
        }
    }
})

export default router
