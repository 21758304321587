<template>
    <router-view :key="platform" />
</template>

<script setup lang="ts">
// Imports
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Types
import { Platform } from '@opteo/types'

// Setup
const { currentRoute } = useRouter()

// PlatformSwitcher
const platform = computed(() => {
    if (currentRoute.value.name === Routes.LinkedAccountsGoogle) {
        return Platform.Platform.GoogleAds
    }
    if (currentRoute.value.name === Routes.LinkedAccountsMicrosoft) {
        return Platform.Platform.MicrosoftAds
    }
    if (currentRoute.value.name === Routes.LinkedAccountsMeta) {
        return Platform.Platform.MetaAds
    }
    if (currentRoute.value.name === Routes.LinkedAccountsLinkedIn) {
        return Platform.Platform.LinkedInAds
    }
})
</script>
