<template>
    <PageHeader title="Accounts">
        <template #titleSuffix>
            <!-- View Switcher -->
            <Menu
                :items="viewItems"
                :buttonLabel="currentView.label"
                :showKeyboardShortcuts="false"
                buttonSize="extra-small"
                placement="bottom-start"
            />
        </template>
        <template #right>
            <!-- Sort -->
            <SelectableList
                v-if="above480"
                type="sort"
                v-model="sortedBy"
                :items="sortItems"
                :popoutWidth="160"
                :offset="[4, 6]"
                buttonSize="small"
                placement="bottom-end"
            />
            <!-- Date Range -->
            <Menu
                v-if="above960"
                :items="dateRangeItems"
                :buttonLabel="currentDateRange.label"
                :popoutWidth="148"
                :offset="[4, 6]"
                :showKeyboardShortcuts="false"
                buttonIconBefore="Calendar"
                buttonSize="small"
                placement="bottom-end"
            />
            <!-- Settings -->
            <Button
                color="secondary"
                iconBefore="Settings"
                size="small"
                @click="toggleAccountsSettingsModal"
            >
                Settings
            </Button>
        </template>
    </PageHeader>

    <PageContent>
        <!-- /init -->
        <AccountsInit v-if="isInit" />

        <!-- Skeleton Rows -->
        <Stack
            v-else-if="loading"
            direction="column"
            align="start"
            :gap="0"
            :padding="[0, 0, 12, 0]"
            grow
        >
            <AccountGroup loading>
                <AccountsRow
                    v-for="skeletonId in 20"
                    :key="skeletonId"
                    :account="{}"
                    :hoverable="false"
                />
            </AccountGroup>
        </Stack>

        <!-- Account Groups -->
        <Stack
            v-else-if="truncatedDomainList?.length"
            direction="column"
            align="start"
            :gap="0"
            :padding="[0, 0, 12, 0]"
            :firstOnTop="false"
            grow
        >
            <AccountGroup
                v-for="group in groupsList"
                :id="group.id"
                :key="group.key"
                :color="group.color"
                :name="group.name"
                :mode="group.mode"
                :count="group.accounts.length"
                @createGroup="toggleCreateGroupModal"
            >
                <AccountsRow
                    v-for="account in group.accounts"
                    :key="account.accountId"
                    :account="account"
                />
            </AccountGroup>
        </Stack>

        <!-- No Accounts -->
        <EmptyState
            v-else-if="userHasNoAccounts"
            icon="ExclamationExtraLarge"
            headline="No Linked Accounts"
            message="You currently have no accounts linked to Opteo. Click the button below to link an existing account or import a new one from a supported platform."
            :contentMaxWidth="296"
            :padding="[48, 0]"
            hideContainer
        >
            <Button color="secondary" iconAfter="ArrowUpRight" @click="goToLinkedAccounts">
                Linked Accounts
            </Button>
        </EmptyState>

        <!-- No Search Results -->
        <EmptyState
            v-else
            icon="SearchExtraLarge"
            :headline="searchedDomain"
            message="No accounts matching your search query. Click the button below to clear your search."
            :contentMaxWidth="272"
            hideContainer
        >
            <Button color="secondary" @click="handleResetClick">Clear Search Query</Button>
        </EmptyState>

        <!-- FloatingFooter -->
        <FloatingFooter
            v-model="showFooter"
            :showShield="true"
            :width="720"
            :padding="[12, 14, 12, 16]"
            sticky
        >
            <Stack align="center" :gap="12" width="100%">
                <IconButton
                    :size="32"
                    icon="Search"
                    color="floating-footer"
                    circle
                    @click="accountSearchInput.focus()"
                />
                <input
                    ref="accountSearchInput"
                    v-model="searchedDomain"
                    placeholder="Search accounts"
                    class="search-input"
                    @keydown.enter.prevent="gotoFirstSearchedDomain"
                    :autofocus="isDesktop ? true : false"
                />
            </Stack>
            <template #right>
                <Button color="floating-footer" iconBefore="Plus" @click="toggleCreateGroupModal">
                    Create Group
                </Button>
                <Spacer :width="8" />
                <Button
                    color="floating-footer"
                    iconAfter="ArrowUpRight"
                    @click="goToLinkedAccounts"
                >
                    Linked Accounts
                </Button>
            </template>
        </FloatingFooter>
    </PageContent>

    <!-- Onboarding -->
    <OnboardingTour
        step-name="tour_domainlist"
        :buttonText="'Continue to Accounts'"
        contentMaxWidth="382px"
        :offsetBottom="84"
    >
        <template v-slot:header>Accounts</template>
        <template v-slot:copy>
            Accounts is the home for all your marketing accounts in Opteo. Group accounts using the
            Create Group button. Filter using the options at the top of the page. To open an account
            and start pushing improvements, click on one of the account rows.
        </template>
    </OnboardingTour>

    <!-- Modals -->
    <AccountsSettingsModal v-model="showAccountsSettingsModal" />
    <CreateGroupModal v-model="showCreateGroupModal" />
</template>

<script setup lang="ts">
// Imports
import { ref, computed, onMounted } from 'vue'
import { useInfiniteScroll } from '@vueuse/core'
import { pushToDatalayer } from '@/lib/datalayer'
import { SortBy } from '@/lib/accountList'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useRouter, useRoute } from 'vue-router'
import { Routes } from '@/router/routes'
import { AccountGroup as OptAccountGroup, User } from '@opteo/types'

// Composition
import { useUser } from '@/composition/user/useUser'
import { useAccountList } from '@/composition/user/useAccountList'
import { useAccountGroups } from '@/composition/accountGroups/useAccountGroups'
import { useTeam } from '@/composition/user/useTeam'

// Components
import {
    Button,
    SelectableList,
    FloatingFooter,
    IconButton,
    Spacer,
    Stack,
    EmptyState,
    Menu,
    useDevice,
    useMediaQuery,
} from '@opteo/components-next'
import AccountsSettingsModal from '@/components/accounts/Modals/AccountsSettingsModal.vue'
import CreateGroupModal from '@/components/accounts/Modals/CreateGroupModal.vue'
import AccountGroup from '@/components/accounts/AccountGroup.vue'
import AccountsInit from '@/components/accounts/AccountsInit.vue'
import AccountsRow from '@/components/accounts/AccountsRow/AccountsRow.vue'

import OnboardingTour from '@/components/user/OnboardingTour.vue'

// Setup
const route = useRoute()
const { user } = useUser()
const { isDesktop } = useDevice()
const {
    searchedDomain,
    gotoFirstSearchedDomain,
    userHasNoAccounts,
    sortedBy,
    filteredDomainList,
    resetSearch,
    domainListLoading,
} = useAccountList()
const { accountGroups, ungroupedGroup, unassignedManager } = useAccountGroups()
const { team } = useTeam()

const { above480, above960 } = useMediaQuery()

const loading = computed(() => !user.value || domainListLoading.value)

const { push, currentRoute } = useRouter()

const isInit = computed(() => currentRoute.value.name === Routes.AccountCentreInit)

const goToLinkedAccounts = () => push({ name: Routes.LinkedAccounts })

const intercom = useIntercom()

// ——————————— Temporary

const showFooter = ref(true)
const accountSearchInput = ref()
const sortItems = [
    { label: 'Account Name', value: SortBy.Name },
    { label: 'Spend', value: SortBy.Spend },
    { label: 'Conversions', value: SortBy.Conversions },
    { label: 'Issues', value: SortBy.Issues },
    { label: 'Budget Status', value: SortBy.Budget },
    { label: 'Goal Status', value: SortBy.Goal },
    { label: 'Last Active', value: SortBy.LastActive },
]
const handleResetClick = () => {
    searchedDomain.value = ''
    accountSearchInput.value.focus()
}

const currentView = ref({
    label: 'Account Groups',
    value: 'accountGroups',
})
const viewItems = [
    {
        label: 'Account Groups',
        value: 'accountGroups',
        action: () =>
            (currentView.value = {
                label: 'Account Groups',
                value: 'accountGroups',
            }),
    },
    {
        label: 'Team Members',
        value: 'teamMembers',
        action: () =>
            (currentView.value = {
                label: 'Team Members',
                value: 'teamMembers',
            }),
    },
]

const currentDateRange = ref({
    label: 'Last 30 Days',
    value: '30d',
})
const dateRangeItems = [
    {
        label: 'Last 30 Days',
        value: '30d',
        action: () =>
            (currentDateRange.value = {
                label: 'Last 30 Days',
                value: '30d',
            }),
    },
    {
        label: 'Last 90 Days',
        value: '90d',
        action: () =>
            (currentDateRange.value = {
                label: 'Last 90 Days',
                value: '90d',
            }),
    },
    {
        label: 'Last 180 Days',
        value: '180d',
        action: () =>
            (currentDateRange.value = {
                label: 'Last 180 Days',
                value: '180d',
            }),
    },
    {
        label: 'Last Year',
        value: 'year',
        action: () =>
            (currentDateRange.value = {
                label: 'Last Year',
                value: 'year',
            }),
    },
]

// Modals
const showAccountsSettingsModal = ref(false)
const toggleAccountsSettingsModal = () => {
    showAccountsSettingsModal.value = !showAccountsSettingsModal.value
}

const showCreateGroupModal = ref(false)
const toggleCreateGroupModal = () => {
    showCreateGroupModal.value = !showCreateGroupModal.value
}

// ——————————— End Temporary

// autofocusSearch
const autofocusSearch = () => {
    if (isDesktop.value === true) {
        accountSearchInput.value.focus()
    } else return
}

onMounted(() => {
    intercom.trackEvent('60_saw_client_list')
    pushToDatalayer('gtm_saw_client_centre')
    resetSearch()
    autofocusSearch()
})

// Infinite Scroll

const isAccountViewMode = computed(() => currentView.value.value === 'accountGroups')

// Account groups list including ungrouped in last position
const accountGroupsList = computed(() => {
    return [...(accountGroups.value ?? []), ungroupedGroup].map(group => {
        return {
            id: group.accountGroupId,
            key: `account-group-${group.accountGroupId ?? group.name}`,
            name: group.name,
            color: group.colour,
            mode: 'account' as const,
            accounts: (truncatedDomainList.value ?? []).filter(
                account => account.accountGroupId === group.accountGroupId
            ),
        }
    })
})

// Managers list including unassigned in last position
const managersList = computed(() => {
    return [...(team.value ?? []), unassignedManager].map(manager => {
        return {
            id: manager.user_id,
            key: `manager-${manager.user_id ?? manager.name}`,
            name: manager.name,
            color: undefined,
            mode: 'user' as const,
            accounts: (truncatedDomainList.value ?? []).filter(
                account => account.managerUserId === manager.user_id
            ),
        }
    })
})

const groupsList = computed(() => {
    return isAccountViewMode.value ? accountGroupsList.value : managersList.value
})

// Sort the accounts by their account groups or manager
// to ensure infinite scrolling works correctly with group containers
const orderedDomainList = computed(() => {
    const idField = isAccountViewMode.value
        ? ('accountGroupId' as const)
        : ('managerUserId' as const)

    const sortedIds: (OptAccountGroup.ID | User.ID | null)[] =
        (isAccountViewMode.value
            ? accountGroups.value?.map(group => group.accountGroupId)
            : team.value?.map(manager => manager.user_id)) ?? []

    // Add ungrouped / unassigned to the end
    sortedIds.push(
        isAccountViewMode.value ? ungroupedGroup.accountGroupId : unassignedManager.user_id
    )

    return (filteredDomainList.value ?? []).sort(
        (a, b) => sortedIds.indexOf(a[idField]) - sortedIds.indexOf(b[idField])
    )
})

const listTruncationCount = ref(25)
const truncatedDomainList = computed(() => {
    return orderedDomainList.value.slice(0, listTruncationCount.value)
})

useInfiniteScroll(
    document,
    () => {
        listTruncationCount.value = listTruncationCount.value + 25
    },
    { distance: 500 } // 500px from bottom, allow more rows to be shown
)
</script>

<style scoped lang="scss">
.search-input {
    all: unset;
    @include w-100;
    @include body;
    @include foreground;
}
.search-input::placeholder {
    @include foreground-minus-three;
}
</style>
