export type ErrorTypes =
    | 'externalPolicyDescription='
    | 'PolicyViolationError'
    | 'no_branding_campaigns_detected'
    | 'no_audiences_with_display_campaigns_found'
    | 'link_still_broken'
    | 'shared_set_owned_by_manager_account'
    | 'read_only_domain'
    | 'policy_violation'
    | 'resource_not_found'
    | 'asset_count_limit_exceeded'
    | 'keyword_match_type_invalid'

export type ImprovementError = {
    [error in ErrorTypes]: {
        long?: string
        dynamicLong?: (error_message: string) => string
        short: string
    }
}

export const improvementErrors: ImprovementError = {
    'externalPolicyDescription=': {
        long: `It looks like your content violated Google's content policy. Retry with different content, or perform this task manually in Google Ads and request an exception.`,
        short: 'Policy violation error',
    },
    PolicyViolationError: {
        long: `It looks like your content violated Google's content policy. Retry with different content, or perform this task manually in Google Ads and request an exception.`,
        short: 'Policy violation error',
    },
    no_branding_campaigns_detected: {
        long: `We couldn't find any branding keywords in your account. We were searching for any of the examples given in the improvement.`,
        short: `No branding campaigns found`,
    },
    no_audiences_with_display_campaigns_found: {
        long: `We couldn't find any display campaigns using your audiences. Please check that your Google Ads account is configured correctly.`,
        short: 'No remarketing campaigns found',
    },
    link_still_broken: {
        long: `Link does not appear to work as expected. Check your page configuration and try again.`,
        short: `Link still broken`,
    },
    shared_set_owned_by_manager_account: {
        long: `We couldn't make changes to your shared negative list because it's owned by a manager account.`,
        short: `Can't modify shared negative list`,
    },
    read_only_domain: {
        dynamicLong: (error_message: string) => {
            const email = error_message.split(':')[1] || 'your connection'
            return `We couldn't make changes to this account. Please ensure that ${email} has a "standard" or "admin" access-level in Google Ads, not "read-only".`
        },
        short: `Can't modify read-only account`,
    },
    policy_violation: {
        dynamicLong: (error_message: string) => {
            const [description, policy_name, violating_text] = error_message.split(':').slice(1)
            return `${description} Violating text: ${violating_text}. Reason: ${policy_name}.`
        },
        short: `Google Ads policy violation`,
    },
    resource_not_found: {
        dynamicLong: (error_message: string) => {
            const [part_of_account] = error_message.split(':').slice(1)
            return `The ${part_of_account} we tried to modify no longer exists.`
        },
        short: `Cannot modify deleted entity`,
    },
    asset_count_limit_exceeded: {
        long: 'Cannot add additional assets beyond limit.',
        short: 'Asset count limit exceeded',
    },
    keyword_match_type_invalid: {
        long: 'Unable to add a keyword of this match type to this campaign. Consider changing the "Broad Match Keywords" campaign setting to "off" on ads.google.com',
        short: 'Invalid keyword match type',
    },
}
