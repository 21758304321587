<template>
    <Stack ref="stackRef">
        <Popout
            v-model="popoutOpen"
            :offset="[-1, 6]"
            :appendTo="stackEl"
            trapFocus
            externalToggleButton
        >
            <Button
                @click="popoutOpen = !popoutOpen"
                iconAfter="CaretDown"
                color="secondary"
                size="small"
            >
                <template #before>
                    <Skeleton
                        v-if="account.id?.includes('skeleton')"
                        :width="16"
                        :height="16"
                        color="foreground"
                    />
                    <ProfileImage
                        v-else
                        :size="16"
                        :imageUrl="profileImageUrl"
                        :boringAvatar="getAvatarUrl(userId)"
                        :noImage="!connected"
                        :isActive="connected"
                        skeletonColor="foreground"
                    />
                </template>
                <Skeleton
                    v-if="account.id?.includes('skeleton')"
                    :width="Math.random() * 20 + 56"
                    :height="10"
                    color="foreground"
                />
                <span v-else-if="connected">Connected</span>
                <span v-else="connected">Disconnected</span>
            </Button>
            <template #content>
                <Container
                    color="background-plus-two"
                    shadow="large"
                    :borderRadius="18"
                    :width="connectedAfterTippyClosed ? 268 : 276"
                    hideOverflow
                >
                    <Stack direction="column" align="start" :padding="[18, 20, 14, 20]" :gap="16">
                        <Button
                            v-if="connectedAfterTippyClosed"
                            :loading="buttonLoading"
                            color="red"
                            iconBefore="Unlink"
                            size="small"
                            @click="updateLinkedStatus(false)"
                        >
                            Disconnect Account
                        </Button>
                        <Button
                            v-else
                            color="green"
                            :loading="buttonLoading"
                            iconBefore="Link"
                            size="small"
                            @click="updateLinkedStatus(true)"
                        >
                            Connect Account
                        </Button>
                        <Text
                            v-if="connectedAfterTippyClosed"
                            as="p"
                            size="body-extra-small"
                            color="foreground-minus-one"
                        >
                            Please note, disconnecting an account will remove it from
                            <b>your Accounts list only</b>. No other team members will be affected.
                        </Text>
                        <Text v-else as="p" size="body-extra-small" color="foreground-minus-one">
                            Please note, connecting an account will add it to
                            <b>your Accounts list only</b>. No other team members will be affected.
                        </Text>
                    </Stack>
                </Container>
            </template>
        </Popout>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { ref, computed } from 'vue'
import { delay } from '@opteo/promise'

// Types
import { Team } from '@opteo/types'

// Composition
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'

// Components
import {
    Popout,
    Button,
    Container,
    Stack,
    Text,
    ProfileImage,
    showSnackbar,
    Skeleton,
} from '@opteo/components-next'

// Props
const props = defineProps<{
    account: Team.Account
}>()

// Setup
const { userId, profileImageUrl, updateCurrentUserLinkedStatus } = useLinkedAccounts()
const { getAvatarUrl } = useBoringAvatar()
const popoutOpen = ref(false)
const connectedStatusOverride = ref<undefined | boolean>()
const buttonLoading = ref(false)

// Computed
const connected = computed(() => {
    // Handle loading/skeleton state
    if (!Array.isArray(props.account.teamMembers)) {
        return false
    }

    return !!props.account.teamMembers.find(member => member.userId === userId.value)?.active
})
const connectedAfterTippyClosed = computed(() => connectedStatusOverride.value ?? connected.value)

// Functions
const updateLinkedStatus = async (newLinkedStatus: boolean) => {
    connectedStatusOverride.value = !newLinkedStatus // keep showing the current button until after the tippy is closed
    buttonLoading.value = true

    try {
        await updateCurrentUserLinkedStatus(props.account.accountId, newLinkedStatus)
        await delay(250) // time for the tippy to animate out

        connectedStatusOverride.value = undefined

        buttonLoading.value = false
    } catch {
        connectedStatusOverride.value = undefined
        buttonLoading.value = false

        showSnackbar({
            message: `Something went wrong linking or unlinking this account`,
            timeout: 4000,
        })
    }
}

// appendTo
const stackRef = ref<InstanceType<typeof Stack> | null>(null)
const stackEl = computed(() => {
    return stackRef.value?.$el ?? document.body
})
</script>
