<template>
    <Container v-if="!loading" :maxWidth="512" :borderRadius="22">
        <Stack direction="column" :gap="0" :padding="[18, 22]">
            <Text as="p" size="body-small">
                You are subscribed to the <b>{{ currentPlan?.name }}</b> plan, which costs
                <b>
                    <Money :value="currentPlan?.price" :currency="currentPlanCurrency" />
                </b>
                per {{ currentPlan?.period_interval }}.

                <span v-if="currentPlan.spend_limit && currentPlan?.account_limit">
                    Your plan includes
                    <b>{{ currentPlan.account_limit }}</b> linked accounts and
                    <b><Money :value="currentPlan.spend_limit" currency="USD" /></b>
                    spend per month. You have
                    <b>{{ totalActiveAccounts }}</b>
                    {{ totalActiveAccounts > 1 ? 'accounts' : 'account' }}
                    linked to Opteo with a monthly spend of
                    <b><Money :value="last30DaySpend" currency="USD" /></b>.
                </span>
                <span v-else-if="currentPlan?.spend_limit">
                    Your plan includes <b>Unlimited</b> linked accounts and
                    <b><Money :value="currentPlan.spend_limit" currency="USD" /></b>
                    spend per month. You have
                    <b>{{ totalActiveAccounts }}</b>
                    {{ totalActiveAccounts > 1 ? 'accounts' : 'account' }}
                    linked to Opteo with a monthly spend of
                    <b><Money :value="last30DaySpend" currency="USD" /></b>.
                </span>
                <span v-else>
                    Your plan includes <b>Unlimited</b> linked accounts and <b>Unlimited</b> spend
                    per month. You have
                    <b>{{ totalActiveAccounts }}</b>
                    {{ totalActiveAccounts > 1 ? 'accounts' : 'account' }}
                    linked to Opteo with a monthly spend of
                    <b><Money :value="last30DaySpend" currency="USD" /></b>.
                </span>
                <Spacer :height="10" />
                Your next invoice will be for
                <b> <Money :value="nextInvoiceAmount" :currency="currentPlanCurrency" /> </b>,
                billed on <b>{{ nextChargeDate }}</b
                >.
            </Text>
        </Stack>
        <!-- Linked Accounts -->
        <Stack direction="column" align="start" :gap="10" borderTop :padding="[18, 22, 20, 22]">
            <Text as="h6" size="headline-six">
                {{ totalActiveAccounts }} Linked
                {{ totalActiveAccounts === 1 ? 'Account' : 'Accounts' }}
            </Text>
            <ProgressBar
                :progress="accountRatio"
                :color="spendProgressBarColor"
                :transitionDuration="0"
            />
            <Text v-if="currentPlan?.account_limit" size="label" color="foreground-minus-one">
                {{ remainingAccounts }}
                {{ remainingAccounts === 1 ? 'account' : 'accounts' }} remaining
            </Text>
            <Text v-else size="label" color="foreground-minus-one">
                Unlimited accounts remaining
            </Text>
        </Stack>
        <!-- Total Spend -->
        <Stack direction="column" align="start" :gap="10" borderTop :padding="[18, 22, 20, 22]">
            <Text as="h6" size="headline-six">
                <Money :value="last30DaySpend" currency="USD" /> Monthly Spend
            </Text>
            <ProgressBar
                id="progressBar"
                :progress="spendRatio"
                :color="accountProgressBarColor"
                :transitionDuration="0"
            />
            <Text v-if="currentPlan?.spend_limit" size="label" color="foreground-minus-one">
                <Money :value="remainingSpend" currency="USD" /> spend remaining
            </Text>
            <Text v-else size="label" color="foreground-minus-one">
                Unlimited spend remaining
            </Text>
        </Stack>
    </Container>
    <Container v-else width="100%" :maxWidth="512" :borderRadius="22">
        <Stack direction="column" :gap="0" :padding="[18, 22]">
            <Stack :height="20" width="100%" align="center">
                <Skeleton :width="456" maxWidth="100%" :height="10" />
            </Stack>
            <Stack :height="20" width="100%" align="center">
                <Skeleton :width="999" maxWidth="100%" :height="10" />
            </Stack>
            <Stack :height="20" width="100%" align="center">
                <Skeleton :width="432" maxWidth="100%" :height="10" />
            </Stack>
            <Spacer :height="10" />
            <Stack :height="20" width="100%" align="center">
                <Skeleton :width="360" maxWidth="100%" :height="10" />
            </Stack>
        </Stack>
        <Stack direction="column" align="start" :gap="10" borderTop :padding="[18, 22, 20, 22]">
            <Stack :height="18" width="100%" align="center">
                <Skeleton :width="128" maxWidth="100%" :height="12" />
            </Stack>
            <Skeleton :width="999" maxWidth="100%" :height="6" />
            <Stack :height="12" width="100%" align="center">
                <Skeleton :width="156" maxWidth="100%" :height="8" />
            </Stack>
        </Stack>
        <Stack direction="column" align="start" :gap="10" borderTop :padding="[18, 22, 20, 22]">
            <Stack :height="18" width="100%" align="center">
                <Skeleton :width="168" maxWidth="100%" :height="12" />
            </Stack>
            <Skeleton :width="999" maxWidth="100%" :height="6" />
            <Stack :height="12" width="100%" align="center">
                <Skeleton :width="140" maxWidth="100%" :height="8" />
            </Stack>
        </Stack>
    </Container>
</template>

<script setup lang="ts">
// Imports
import { ref, computed } from 'vue'
import max from 'lodash-es/max'

// Composition
import { useUser } from '@/composition/user/useUser'
import { useSubscription } from '@/composition/billing/useSubscription'

// Components
import {
    Container,
    Stack,
    Text,
    Money,
    ProgressBar,
    Spacer,
    Skeleton,
} from '@opteo/components-next'

// Setup
const { currentPlan, currentPlanCurrency, userInfo } = useUser()
const { userType, nextChargeDate, coupon, loading } = useSubscription()

// Computed
const totalActiveAccounts = computed(() => userInfo.value?.total_active_accounts ?? 0)
const last30DaySpend = computed(() => userInfo.value?.total_usd_spend_last_30d ?? 0)

const spendRatio = computed(() => {
    if (last30DaySpend.value > (currentPlan.value?.spend_limit ?? 0)) {
        return 1.1
    }
    return last30DaySpend.value / (currentPlan.value?.spend_limit ?? 0)
})
const accountRatio = computed(() => {
    if (totalActiveAccounts.value > (currentPlan.value?.account_limit ?? 0)) {
        return 1.1
    }
    return totalActiveAccounts.value / (currentPlan.value?.account_limit ?? 0)
})

const remainingSpend = computed(() =>
    max([(currentPlan.value?.spend_limit ?? 0) - last30DaySpend.value, 0])
)
const remainingAccounts = computed(() =>
    max([(currentPlan.value?.account_limit ?? 0) - totalActiveAccounts.value, 0])
)

const spendProgressBarColor = computed(() => {
    if (accountRatio.value > 1 && currentPlan.value?.account_limit) {
        return 'red'
    } else if (!currentPlan.value?.account_limit) {
        return 'green'
    }
})
const accountProgressBarColor = computed(() => {
    if (spendRatio.value > 1 && currentPlan.value?.spend_limit) {
        return 'red'
    } else if (!currentPlan.value?.spend_limit) {
        return 'green'
    }
})

const nextInvoiceAmount = computed(() => {
    // if canceled, return 0.
    if (userType.value.includes('cancel')) {
        return 0
    }

    const planPrice = currentPlan.value?.price || 0

    // if no coupon, return plan price.
    if (!coupon.value) {
        return planPrice
    }

    // if coupon specifies a fixed amount,
    // subtract it and ensure it doesn't go below 0.
    if (coupon.value.amount_off) {
        return Math.max(planPrice - coupon.value.amount_off / 100, 0)
    }

    // otherwise, assume a percentage discount.
    // if coupon.value.percent_off is not provided, default to 0%.
    const discountPercentage = coupon.value.percent_off || 0
    const discountMultiplier = 1 - discountPercentage / 100

    return planPrice * discountMultiplier
})
</script>

<style scoped lang="scss"></style>
