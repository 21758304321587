<template>
    <Table
        v-if="teamAccountsLoading || filteredUnlinkedAccounts.length > 0"
        ref="tableRef"
        :columns="unlinkedTableHeaders"
        :rows="filteredUnlinkedAccounts"
        selectionMode="filter"
        v-model="selectedUnlinked"
        @select="handleRowSelect($event, false)"
        @select:all="selectAllAccounts"
        :loading="teamAccountsLoading"
        :columnWidths="unlinkedColumnWidths"
        :perPage="24"
        :rowHeight="60"
        :headerHeight="52"
        :height="tableHeight"
        stickyHeader
        :borderRadius="0"
        cellSpacing="medium"
        paginationMode="intersection"
        :sort="{ column: 'spend30d', direction: 'desc' }"
        color="transparent"
    >
        <!-- Header -->
        <template #changelogEmail-header>
            <Text as="span" :wrap="false">Changelog Email</Text>
            <Popout
                :modelValue="showChangelogEmailPopout"
                trigger="mouseenter"
                placement="top"
                :offset="[0, 10]"
            >
                <Info />
                <template #content>
                    <Container
                        color="background-plus-two"
                        shadow="large"
                        :padding="[14, 18]"
                        :borderRadius="18"
                        :width="322"
                        hideOverflow
                    >
                        <Text as="p" size="body-small" align="left">
                            Whenever a change is pushed via Opteo, the email address you select in
                            this column will be displayed in the <b>Change History</b> (or
                            equivalent) section inside your platform account.
                        </Text>
                        <Spacer :height="10" />
                        <Text as="p" size="body-small" align="left">
                            To use another email address, import the parent account by clicking the
                            <b>Import Account</b> button, using your preferred email address to sign
                            in.
                        </Text>
                    </Container>
                </template>
            </Popout>
        </template>
        <!-- Rows -->
        <template #name-data="{ row }">
            <LinkedAccountsAccountName :account="row" :linked="false" />
        </template>
        <template #spend30d-data="{ row }">
            <LinkedAccountsSpendCell :account="row" />
        </template>
        <template #changelogEmail-data="{ row }">
            <LinkedAccountsChangelogSelect
                :account="row"
                :available-connections="row.availableConnections"
                :account-id="row.accountId"
            />
        </template>
        <template #teamMembers-data="{ row }">
            <LinkedAccountsTeamMembers :account="row" :isLinked="false" />
        </template>
    </Table>

    <!-- All accounts are linked -->
    <EmptyState
        v-else-if="unlinkedAccountsCount === 0"
        icon="ExclamationExtraLarge"
        headline="No Accounts Available"
        :height="tableHeight"
        :contentMaxWidth="350"
        :padding="[48, 0]"
        hideContainer
    >
        <template #message>
            <Text as="p" size="body-small" align="center" color="foreground-minus-one">
                You currently have no accounts available to link to Opteo. Switch to the
                <b>Linked</b> tab to see your linked accounts or click <b>Import Account</b> to
                import a new one.
            </Text>
        </template>
    </EmptyState>
</template>

<script setup lang="ts">
// Imports
import { onUnmounted, ref, computed } from 'vue'

// Types
import { Platform } from '@opteo/types'

// Composition
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'

// Components
import {
    Table,
    Text,
    Popout,
    Container,
    EmptyState,
    Spacer,
    Info,
    useMediaQuery,
} from '@opteo/components-next'
import LinkedAccountsAccountName from '@/components/linkedAccounts/LinkedAccountsAccountName.vue'
import LinkedAccountsSpendCell from '@/components/linkedAccounts/LinkedAccountsSpendCell.vue'
import LinkedAccountsTeamMembers from '@/components/linkedAccounts/LinkedAccountsTeamMembers.vue'
import LinkedAccountsChangelogSelect from '@/components/linkedAccounts/LinkedAccountsChangelogSelect.vue'

// Setup
const {
    unlinkedTableHeaders,
    unlinkedColumnWidths,
    filteredUnlinkedAccounts,
    teamAccountsLoading,
    unlinkedVisitedTS,
    unlinkedVisitedUnmountTS,
    handleRowSelect,
    unlinkedAccountsCount,
    selectedUnlinked,
    selectAllAccounts,
    showChangelogEmailPopout,
    platform,
} = useLinkedAccounts()
const tableRef = ref()
const { above1024 } = useMediaQuery()
const tableHeight = computed(() => {
    if (above1024.value === true) {
        return 'calc(100dvh - 208px)'
    } else {
        return 'calc(100dvh - 288px)'
    }
})

// Timestamp
unlinkedVisitedTS.value = new Date()
onUnmounted(() => {
    unlinkedVisitedUnmountTS.value = new Date()
})

// defineExpose
defineExpose({ tableRef })
</script>
