<template>
    <div class="page-container">
        <div class="inner">
            <div class="loading-slides-wrapper">
                <marquee-text :duration="50" :repeat="4">
                    <div class="slides">
                        <OverviewLoaderSlide />
                        <SearchTermCoverageLoaderSlide />
                        <QualityScoreLoaderSlide />
                        <ProjectedSpendLoaderSlide />
                    </div>
                </marquee-text>
            </div>
            <transition name="fade" mode="out-in">
                <div class="copy-container" v-if="!hasTimedOut">
                    <div class="headline">
                        <Text as="p" size="f-5" weight="600" align="center">
                            <span class="message-1">Fetching data from Google Ads..</span>
                            <span class="message-2">Calculating scores..</span>
                            <span class="message-3">Generating Scorecard..</span>
                        </Text>
                    </div>
                    <Spacer height="1rem" />
                    <Text as="p" size="f-8" align="center">
                        Opteo is gathering your performance data and generating a new Scorecard.
                        This process usually takes a few moments, but larger accounts may require a
                        couple of minutes to complete.
                    </Text>
                    <div class="progress-bar-container">
                        <ProgressBar :progress="progressPercent" />
                    </div>
                </div>
                <div class="copy-container wide" v-else>
                    <div class="headline">
                        <Text as="p" size="f-5" weight="600" align="center">
                            This is taking longer than usual..
                        </Text>
                    </div>
                    <Spacer height="1rem" />
                    <Text as="p" size="f-8" align="center">
                        Click the button below to leave this page and continue with other tasks
                        while you wait — your scorecard will continue generating in the background.
                        Alternatively, you can stay on this page until your scorecard has finished
                        generating. If this issue persists, or your Scorecard fails to load, please
                        contact support.
                        <!-- NOTE: in reality we may not actually let them know when it's ready (because we don't know.) -->
                    </Text>
                    <Spacer height="2rem" />
                    <div class="close-button-container">
                        <oButton class="close-button" @clicked="$emit('give-up-on-waiting')">
                            Return to Toolkit
                        </oButton>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, PropType, watch } from 'vue'

import anime from 'animejs/lib/anime.es.js'
// import { Text, Spacer, oButton } from '@opteo/components-next'
import MarqueeText from 'vue-marquee-text-component'

import OverviewLoaderSlide from './OverviewLoaderSlide.vue'
import SearchTermCoverageLoaderSlide from './SearchTermCoverageLoaderSlide.vue'
import QualityScoreLoaderSlide from './QualityScoreLoaderSlide.vue'
import ProjectedSpendLoaderSlide from './ProjectedSpendLoaderSlide.vue'
import ProgressBar from '@/components/global/ProgressBar.vue'

const props = defineProps({
    status: {
        type: String as PropType<'gathering' | 'calculating' | 'success' | 'error'>,
        required: false,
        default: 'gathering',
    },
    progressPercent: {
        type: Number as PropType<number>, // between 0 and 1
        required: false,
        default: 0,
    },
    hasTimedOut: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
    },
})

// Loader messages
onMounted(() => {
    anime
        .timeline({
            loop: true,
        })
        .add({
            targets: ['.message-1'],
            opacity: [0, 1],
            translateY: ['0.5rem', 0],
            translateZ: 0,
            easing: 'cubicBezier(0.19, 1, 0.22, 1)',
            duration: 500,
            delay: 300,
        })
        .add({
            targets: ['.message-1'],
            opacity: [1, 0],
            translateY: [0, '0.5rem'],
            translateZ: 0,
            easing: 'cubicBezier(0.19, 1, 0.22, 1)',
            duration: 500,
            delay: 4000,
        })
        .add({
            targets: ['.message-2'],
            opacity: [0, 1],
            translateY: ['0.5rem', 0],
            translateZ: 0,
            easing: 'cubicBezier(0.19, 1, 0.22, 1)',
            duration: 500,
            delay: 300,
        })
        .add({
            targets: ['.message-2'],
            opacity: [1, 0],
            translateY: [0, '0.5rem'],
            translateZ: 0,
            easing: 'cubicBezier(0.19, 1, 0.22, 1)',
            duration: 500,
            delay: 4000,
        })
        .add({
            targets: ['.message-3'],
            opacity: [0, 1],
            translateY: ['0.5rem', 0],
            translateZ: 0,
            easing: 'cubicBezier(0.19, 1, 0.22, 1)',
            duration: 500,
            delay: 300,
        })
        .add({
            targets: ['.message-3'],
            opacity: [1, 0],
            translateY: [0, '0.5rem'],
            translateZ: 0,
            easing: 'cubicBezier(0.19, 1, 0.22, 1)',
            duration: 500,
            delay: 4000,
        })
})
</script>

<style scoped lang="scss">
// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .page-container {
// //     @include absolute;
// //     padding-top: 6.75rem;
// //     top: 0;
// //     bottom: 0;
// //     left: 0;
// //     right: 0;

// //     & .inner {
// //         @include w-100;
// //     }

// //     & .slides {
// //         @include flex;
// //         @include items-center;
// //     }
// //     & .copy-container {
// //         @include w-100;
// //         max-width: 25rem;
// //         margin: 0 auto;
// //         @include relative;

// //         & .headline {
// //             @include relative;
// //             height: 2.25rem;
// //         }
// //     }
// // }
// // .copy-container.wide {
// //     max-width: 26rem;
// // }

// // .loading-slides-wrapper {
// //     @include relative;
// // }
// // .loading-slides-wrapper :deep(.marquee-text-wrap) {
// //     @include pt-4;
// //     @include pb-48;
// // }
// // .loading-slides-wrapper :deep(.marquee-text-content .slides svg) {
// //     margin-right: 2rem;
// // }
// // .loading-slides-wrapper::before {
// //     content: '';
// //     width: 25vw;
// //     height: 100%;
// //     @include absolute;
// //     top: 0;
// //     left: 0;
// //     @include z-2;
// //     background: rgb(255, 255, 255);
// //     background: linear-gradient(90deg, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 0) 100%);
// //     pointer-events: none;
// // }
// // .loading-slides-wrapper::after {
// //     content: '';
// //     width: 25vw;
// //     height: 100%;
// //     @include absolute;
// //     top: 0;
// //     right: 0;
// //     @include z-2;
// //     background: rgb(255, 255, 255);
// //     background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%);
// //     pointer-events: none;
// // }

// // .message-1,
// // .message-2,
// // .message-3 {
// //     position: absolute;
// //     left: 0;
// //     opacity: 0;
// //     @include w-100;
// // }

// // .progress-bar-container {
// //     @include ph-16;
// //     @include mt-32;
// // }

// // .close-button-container {
// //     // absolute positioning to avoid layout shift from intial loader
// //     @include absolute;
// //     // button height + progress bar height
// //     bottom: calc(-44px + 6px);
// //     @include w-100;
// //     @include flex;
// //     @include justify-center;
// // }

// // .fade-enter-active,
// // .fade-leave-active {
// //     transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
// // }

// // .fade-enter-from,
// // .fade-leave-to {
// //     opacity: 0;
// //     transform: translateY(0.75rem);
// // }
// .close-button-container {
// absolute positioning to avoid layout shift from intial loader
// @include absolute;
// button height + progress bar height
//     bottom: calc(-104px + 6px);
//     @include w-100;
//     @include flex;
//     @include justify-center;
//     padding-bottom: 60px;
// }
</style>
