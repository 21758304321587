<template>
    <section>
        <StickyHeader :padding="above480 ? [0, 20] : [0, 14]">
            <template #title>
                <Stack gap="12" align="center">
                    <!-- AccountGroup -->
                    <AccountGroup
                        :id="id"
                        :mode="mode"
                        :color="color"
                        :name="name"
                        :loading="loading"
                    />
                    <!-- Expand/Collapse -->
                    <IconButton
                        :icon="showAccounts === true ? 'Minus' : 'Plus'"
                        color="secondary"
                        :size="28"
                        circle
                        @click="showAccounts = !showAccounts"
                    />
                </Stack>
            </template>
            <template v-if="mode === 'account'" #right>
                <template v-if="!isUngroupedOrUnassigned && above480">
                    <!-- Group Performance -->
                    <Button
                        color="secondary"
                        size="small"
                        iconBefore="Loaders"
                        :readOnly="loading"
                        @click="goToGroupPerformance"
                    >
                        Group Performance
                    </Button>
                    <!-- Group Settings -->
                    <Button
                        color="secondary"
                        size="small"
                        iconBefore="Settings"
                        :readOnly="loading"
                        @click="goToGroupSettings"
                    >
                        Group Settings
                    </Button>
                </template>
                <template v-else-if="!isUngroupedOrUnassigned && !above480">
                    <Menu
                        :buttonSize="'small'"
                        placement="bottom-end"
                        :offset="[4, 6]"
                        :showKeyboardShortcuts="false"
                        :items="[
                            {
                                value: 'groupPerformance',
                                label: 'Group Performance',
                                icon: 'Loaders',
                                action: () => goToGroupPerformance,
                            },
                            {
                                value: 'groupSettings',
                                label: 'Group Settings',
                                icon: 'Settings',
                                action: () => goToGroupSettings,
                            },
                        ]"
                    >
                        <template #button="{ toggleSelect }">
                            <IconButton
                                :icon="'ThreeDots'"
                                color="secondary"
                                :size="32"
                                circle
                                @click="toggleSelect"
                            />
                        </template>
                    </Menu>
                </template>
                <template v-else-if="above480">
                    <!-- GroupOnboarding -->
                    <Popout
                        v-model="showPerformancePopout"
                        trapFocus
                        externalToggleButton
                        placement="bottom"
                        :offset="[0, 8]"
                    >
                        <Button
                            color="secondary"
                            size="small"
                            iconBefore="Loaders"
                            @click="showPerformancePopout = !showPerformancePopout"
                        >
                            Group Performance
                        </Button>
                        <template #content>
                            <Container
                                color="background-plus-two"
                                shadow="large"
                                :padding="[14, 18, 18, 18]"
                                :borderRadius="16"
                                :width="288"
                            >
                                <Stack direction="column" :gap="12" width="100%" align="start">
                                    <Text as="p" size="body-small">
                                        Create an account group to get access to
                                        <b>Group Performance</b>. Account groups are a way to group
                                        cross-platform marketing accounts together in a single
                                        location.
                                    </Text>
                                    <Button
                                        size="small"
                                        color="tertiary"
                                        iconBefore="Plus"
                                        @click="handleCreateGroupClick"
                                    >
                                        Create Group
                                    </Button>
                                </Stack>
                            </Container>
                        </template>
                    </Popout>
                    <Popout
                        v-model="showSettingsPopout"
                        trapFocus
                        externalToggleButton
                        placement="bottom"
                        :offset="[0, 8]"
                    >
                        <Button
                            color="secondary"
                            size="small"
                            iconBefore="Settings"
                            @click="showSettingsPopout = !showSettingsPopout"
                        >
                            Group Settings
                        </Button>
                        <template #content>
                            <Container
                                color="background-plus-two"
                                shadow="large"
                                :padding="[14, 18, 18, 18]"
                                :borderRadius="16"
                                :width="280"
                            >
                                <Stack direction="column" :gap="12" width="100%" align="start">
                                    <Text as="p" size="body-small">
                                        Create an account group to get access to
                                        <b>Group Settings</b>. Account groups are a way to group
                                        cross-platform marketing accounts together in a single
                                        location.
                                    </Text>
                                    <Button
                                        size="small"
                                        color="tertiary"
                                        iconBefore="Plus"
                                        @click="handleCreateGroupClick"
                                    >
                                        Create Group
                                    </Button>
                                </Stack>
                            </Container>
                        </template>
                    </Popout>
                </template>
            </template>
            <template v-else #right>
                <template v-if="above480">
                    <Button color="secondary" size="small" iconAfter="CaretDown">Actions</Button>
                    <Button
                        color="secondary"
                        size="small"
                        iconBefore="Settings"
                        @click="goToTeamSettings"
                    >
                        Team Settings
                    </Button>
                </template>
                <template v-else>
                    <Menu
                        :buttonSize="'small'"
                        placement="bottom-end"
                        :offset="[4, 6]"
                        :showKeyboardShortcuts="false"
                        :items="[
                            {
                                value: 'teamSettings',
                                label: 'Team Settings',
                                icon: 'Settings',
                                action: () => goToTeamSettings,
                            },
                        ]"
                    >
                        <template #button="{ toggleSelect }">
                            <IconButton
                                :icon="'ThreeDots'"
                                color="secondary"
                                :size="32"
                                circle
                                @click="toggleSelect"
                            />
                        </template>
                    </Menu>
                </template>
            </template>
        </StickyHeader>
        <Stack v-show="showAccounts" direction="column" :gap="0" :padding="0">
            <template v-if="count > 0">
                <slot />
            </template>
            <EmptyState
                v-else
                icon="SparklesExtraLarge"
                hideContainer
                :contentMaxWidth="252"
                :padding="above480 ? 48 : [48, 24]"
                message="There are no accounts in this group, to add an account, use the selector in each account row, or open Group Settings."
            />
        </Stack>
    </section>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, computed } from 'vue'
import { AccountGroup as OptAccountGroup, User } from '@opteo/types'

// Components
import {
    StickyHeader,
    Stack,
    Button,
    IconButton,
    Container,
    Text,
    Popout,
    useMediaQuery,
    Menu,
    EmptyState,
} from '@opteo/components-next'
import AccountGroup from '@/components/global/AccountGroup.vue'

// Props
const props = defineProps({
    id: {
        type: [Number, null] as PropType<OptAccountGroup.ID | User.ID | null>,
        required: true,
    },
    mode: {
        type: String as PropType<'account' | 'user'>,
        required: false,
        default: 'account',
    },
    color: {
        type: String as PropType<string>,
        required: false,
        default: 'gray',
    },
    count: {
        type: Number as PropType<number>,
        required: true,
    },
    name: {
        type: String as PropType<string>,
        required: false,
    },
    loading: {
        type: Boolean as PropType<boolean>,
        required: false,
    },
})

// Emits
const emit = defineEmits(['create-group'])

// Setup
const showPerformancePopout = ref(false)
const showSettingsPopout = ref(false)
const { above480 } = useMediaQuery()

// Functions
function handleCreateGroupClick() {
    emit('create-group')
    showPerformancePopout.value = false
    showSettingsPopout.value = false
}
function goToGroupPerformance() {
    console.log('Go to Group Performance')
}
function goToGroupSettings() {
    console.log('Go to Group Settings')
}
function goToTeamSettings() {
    console.log('Go to Team Settings')
}

// Computed
const isUngroupedOrUnassigned = computed(() => {
    const groupName = props.name?.toLowerCase()
    if (groupName === 'ungrouped' || groupName === 'unassigned') {
        return true
    } else return false
})

// Setup
const showAccounts = ref(true)
</script>

<style scoped lang="scss">
section {
    @include w-100;
    @include relative;
    @include bb;
    @include border-background;
}
</style>
