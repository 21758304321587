<template>
    <!-- Header -->
    <PageHeader title="Linked Accounts">
        <template #right>
            <!-- Platform -->
            <Menu
                :items="platformItems"
                :popoutWidth="188"
                :offset="[4, 6]"
                placement="bottom-end"
                @item-clicked="switchPlatform"
            >
                <template #button="{ toggleSelect }">
                    <Button
                        @click="toggleSelect"
                        size="small"
                        color="secondary"
                        iconAfter="CaretDown"
                    >
                        <template #before>
                            <component :is="activePlatform.icon" :size="12" />
                        </template>
                        {{ activePlatform.label }}
                    </Button>
                </template>
            </Menu>
        </template>
    </PageHeader>

    <!-- Content -->
    <PageContent>
        <!-- Limits -->
        <Stack borderBottom>
            <LinkedAccountsLimits />
        </Stack>
        <!-- Router -->
        <router-view />
    </PageContent>

    <!-- AssignTeamMembers Modal -->
    <AssignTeamMembersModal v-model="assignTeamModalOpen" />
</template>

<script setup lang="ts">
// Imports
import { computed, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import { useIntercom } from '@/lib/intercom/useIntercom'

// Composition
import { provideLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import { useUser } from '@/composition/user/useUser'

// Types
import { Platform } from '@opteo/types'

// Components
import {
    Stack,
    Menu,
    Button,
    GoogleAdsLogo,
    MicrosoftAdsLogo,
    MetaAdsLogo,
    LinkedInAdsLogo,
    showSnackbar,
} from '@opteo/components-next'
import AssignTeamMembersModal from '@/components/linkedAccounts/Modals/AssignTeamMembersModal.vue'
import LinkedAccountsLimits from '@/components/linkedAccounts/LinkedAccountsLimits.vue'
import { MenuItemType } from '@opteo/components-next/dist/components/buttons/Menu/types'

// Setup
const { assignTeamModalOpen, searchedTeamMember, displayAuthError } = provideLinkedAccounts()
const { betaFeatures } = useUser()
const { push } = useRouter()
const route = useRoute()
const { showNewMessage } = useIntercom()

const platformRoutes = {
    [Platform.Platform.GoogleAds]: Routes.LinkedAccountsGoogle,
    [Platform.Platform.MicrosoftAds]: Routes.LinkedAccountsMicrosoft,
    [Platform.Platform.MetaAds]: Routes.LinkedAccountsMeta,
    [Platform.Platform.LinkedInAds]: Routes.LinkedAccountsLinkedIn,
}

const routeToPlatformIndex = {
    [Routes.LinkedAccountsGoogle]: 0,
    [Routes.LinkedAccountsMicrosoft]: 1,
    [Routes.LinkedAccountsMeta]: 2,
    [Routes.LinkedAccountsLinkedIn]: 3,
}

// Functions
function switchPlatform(selectedPlatform: MenuItemType) {
    const routeName = platformRoutes[selectedPlatform.value]

    if (!routeName) return

    const betaFeatureChecks = {
        [Platform.Platform.MicrosoftAds]: betaFeatures.value?.microsoftGamma,
        [Platform.Platform.MetaAds]: betaFeatures.value?.metaBeta,
        [Platform.Platform.LinkedInAds]: betaFeatures.value?.linkedInBeta,
    }

    if (betaFeatureChecks[selectedPlatform.value] === false) {
        console.warn(`Access to ${selectedPlatform.label} Beta restricted`)
        showSnackbar({
            message: `Access to <b>${selectedPlatform.label} Beta</b> restricted`,
            timeout: 8000,
            actionText: 'Request Access',
            actionHandler: () => showNewMessage(),
        })
        return
    }

    push({ name: routeName })
}

// Computed
const activePlatform = computed(() => {
    const routeName = route.name as keyof typeof routeToPlatformIndex
    if (!routeName || !(routeName in routeToPlatformIndex)) {
        return platformItems.value[0]
    }

    const index = routeToPlatformIndex[routeName]
    return platformItems.value[index] ?? platformItems.value[0]
})

const platformItems = computed(() => [
    {
        label: 'Google Ads',
        value: Platform.Platform.GoogleAds,
        icon: GoogleAdsLogo,
    },
    {
        label: 'Microsoft Ads',
        value: Platform.Platform.MicrosoftAds,
        icon: MicrosoftAdsLogo,
    },
    {
        label: 'Meta Ads',
        value: Platform.Platform.MetaAds,
        icon: MetaAdsLogo,
    },
    {
        label: 'LinkedIn Ads',
        value: Platform.Platform.LinkedInAds,
        icon: LinkedInAdsLogo,
    },
])

// Watch
watch(assignTeamModalOpen, open => {
    if (!open && searchedTeamMember.value.length > 0) {
        searchedTeamMember.value = ''
    }
})

// onMounted
onMounted(() => {
    displayAuthError()
})
</script>

<style scoped lang="scss"></style>
