<template>
    <PageContainer>
        <!-- SettingsSidebar -->
        <SettingsSidebar v-if="above1024" ref="SettingsSidebarRef" />
        <!-- Settings Container -->
        <Stack :gap="0" direction="column" :height="containerHeight" :width="pageWidth">
            <router-view />
        </Stack>
        <!-- SettingsBottomBar -->
        <SettingsBottomBar v-if="!above1024" @menu-clicked="toggleSidebar" />
        <!-- ToggleSidebar -->
        <ToggleSidebar
            position="right"
            :width="300"
            :modelValue="showSidebar"
            :zIndex="4002"
            :borderRadius="24"
            @close="toggleSidebar"
        >
            <SettingsSidebar width="100%" height="100%" @item-clicked="toggleSidebar" />
        </ToggleSidebar>
    </PageContainer>
</template>

<script setup lang="ts">
// Imports
import { ref, computed } from 'vue'
import { useElementSize, useWindowSize } from '@vueuse/core'

// Components
import PageContainer from '@/components/layout/internalPages/PageContainer.vue'
import SettingsSidebar from '@/components/user/settings/SettingsSidebar.vue'
import SettingsBottomBar from '@/components/user/settings/SettingsBottomBar.vue'
import { Stack, useMediaQuery, ToggleSidebar } from '@opteo/components-next'

// Setup
const { above1024 } = useMediaQuery()
const showSidebar = ref(false)

// pageWidth
const SettingsSidebarRef = ref(null)
const { width: SettingsSidebarWidth } = useElementSize(SettingsSidebarRef)
const { width: windowWidth } = useWindowSize()

// containerHeight
const containerHeight = computed(() => {
    if (above1024.value === true) {
        return '100%'
    } else {
        return 'calc(100dvh - 168px)'
    }
})

const pageWidth = computed(() => {
    if (above1024.value === true) {
        return windowWidth.value - SettingsSidebarWidth.value - 82
    } else return windowWidth.value
})

// Functions
const toggleSidebar = () => {
    showSidebar.value = !showSidebar.value
}
</script>

<style scoped lang="scss"></style>
