import { computed, inject, nextTick, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue'
import sumBy from 'lodash-es/sumBy'
import countBy from 'lodash-es/countBy'
import orderBy from 'lodash-es/orderBy'
import round from 'lodash-es/round'
import { useRouter } from 'vue-router'

import { hideSnackbar, oButton, showSnackbar, useNumber } from '@opteo/components-next'
import { useAccount } from '@/composition/account/useAccount'
import { Endpoint, useAPI, authRequest } from '@/composition/api/useAPI'
import { Routes } from '@/router/routes'
import { Gads, Improvement, NgramTool, Platform, Targets } from '@opteo/types'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { delay } from '@opteo/promise'
import { useUser } from '@/composition/user/useUser'
import { calculateCpaClamped, downloadCsv } from '@/lib/globalUtils'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useNGramFilters } from './useNGramFilters'
import { isNGramRes } from './utils'
import _ from 'lodash'

import type { NGramPerformance, NGramRes, PanelItem, SearchTermRes } from './types'

const loadingStateMessages = {
    SEARCH_TERM_VIEW_NOT_CACHED: 'Fetching search terms from Google Ads..',
    BLOCKED_SEARCH_TERM_NOT_CACHED: 'Fetching keywords from Google Ads..',
    UNDERPERFORMING_NGRAMS_NOT_CACHED: 'Fetching underperforming industry n-grams..',
    SEARCH_TERM_INSIGHT_NOT_CACHED: 'Fetching Performance Max search terms..',
    GA4_SESSIONS_NOT_CACHED: 'Fetching bounce rates from Google Analytics..',
}

export function provideNGramTool() {
    // Other composition functions
    const { groupId, isOnLegacyPlan } = useUser()

    const { currentRoute, push } = useRouter()
    const {
        currencyCode,
        accountId,
        performanceMode,
        currencySymbol,
        accountInfo,
        accountName,
        accountPlatform,
    } = useAccount()
    const {
        lookbackWindow,
        excludedCampaigns,
        excludeNGramFilters,
        excludeSearchTermFilters,
        excludeKeywordFilters,
        excludeMatchTypeFilters,
        checkedFilters,
        stringifiedFilters,
        stringifiedCampaignSelection,
        filtersModalOpen,
    } = useNGramFilters()

    const isMicrosoft = computed(() => accountPlatform.value === Platform.Platform.MicrosoftAds)

    // Navigation Logic
    const tabLinks = computed(() => [
        {
            key: 'search-campaigns',
            name: 'Search Campaigns',
            to: { name: Routes.ToolkitNGramFinderSearch },
            count:
                countBy(
                    nGramCampaigns.value,
                    c => c.campaignType === NgramTool.campaignTypeEnum.SEARCH
                ).true ?? 0,
            disabled: !nGramCampaigns.value?.find(
                c => c.campaignType === NgramTool.campaignTypeEnum.SEARCH
            ),
        },
        ...(!isMicrosoft.value
            ? [
                  {
                      key: 'performance-max',
                      name: 'Performance Max',
                      to: { name: Routes.ToolkitNGramToolPmax },
                      count:
                          countBy(
                              nGramCampaigns.value,
                              c => c.campaignType === NgramTool.campaignTypeEnum.PERFORMANCE_MAX
                          ).true ?? 0,
                      disabled: !nGramCampaigns.value?.find(
                          c => c.campaignType === NgramTool.campaignTypeEnum.PERFORMANCE_MAX
                      ),
                  },
              ]
            : []),
        {
            key: 'shopping-campaigns',
            name: 'Shopping Campaigns',
            to: { name: Routes.ToolkitNGramFinderShopping },
            count:
                countBy(
                    nGramCampaigns.value,
                    c => c.campaignType === NgramTool.campaignTypeEnum.SHOPPING
                ).true ?? 0,
            disabled: !nGramCampaigns.value?.find(
                c => c.campaignType === NgramTool.campaignTypeEnum.SHOPPING
            ),
        },
    ])

    const steps = computed(() => [
        { index: 0, title: 'N-Gram Finder' },
        { index: 1, title: finalItemSelectionCount.value === 1 ? 'Add Negative' : 'Add Negatives' },
    ])

    const searchCampaignsActive = computed(() =>
        currentRoute.value.matched.some(record => record.name === Routes.ToolkitNGramFinderSearch)
    )
    const performanceMaxActive = computed(() =>
        currentRoute.value.matched.some(record => record.name === Routes.ToolkitNGramFinderPmax)
    )
    const shoppingActive = computed(() =>
        currentRoute.value.matched.some(record => record.name === Routes.ToolkitNGramFinderShopping)
    )

    const currentAdvertisingChannelType = computed<
        | NgramTool.campaignTypeEnum.SEARCH
        | NgramTool.campaignTypeEnum.PERFORMANCE_MAX
        | NgramTool.campaignTypeEnum.SHOPPING
    >(() => {
        if (searchCampaignsActive.value) {
            return NgramTool.campaignTypeEnum.SEARCH
        }
        if (performanceMaxActive.value) {
            return NgramTool.campaignTypeEnum.PERFORMANCE_MAX
        }
        if (shoppingActive.value) {
            return NgramTool.campaignTypeEnum.SHOPPING
        }
        return NgramTool.campaignTypeEnum.SEARCH
    })

    const currentStepIndex = computed(() => {
        return [
            Routes.ToolkitNGramFinderSearchConfirm,
            Routes.ToolkitNGramFinderPmaxConfirm,
            Routes.ToolkitNGramFinderShoppingConfirm,
        ].includes(currentRoute.value.name as any)
            ? 1
            : 0
    })

    const goToStep0 = () => {
        if (searchCampaignsActive.value) {
            push({ name: Routes.ToolkitNGramFinderSearchSelect })
        } else if (performanceMaxActive.value) {
            push({ name: Routes.ToolkitNGramFinderPmaxSelect })
        } else if (shoppingActive.value) {
            push({ name: Routes.ToolkitNGramFinderShoppingSelect })
        }
    }

    const goToStep1 = () => {
        if (searchCampaignsActive.value) {
            push({ name: Routes.ToolkitNGramFinderSearchConfirm })
        } else if (performanceMaxActive.value) {
            push({ name: Routes.ToolkitNGramFinderPmaxConfirm })
        } else if (shoppingActive.value) {
            push({ name: Routes.ToolkitNGramFinderShoppingConfirm })
        }
    }

    function resetState() {
        goToStep0()
        clearAllNgrams()
        clearAllNegatives()
    }

    function goToNGramSelection() {
        goToStep0()
    }
    function goToNegativeDestination() {
        goToStep1()
    }

    function closeNGramTool() {
        resetState()
        push({ name: Routes.ToolkitTools })
    }

    /**
     * Selected nGrams in the first step
     */
    const initialNgramSelection = ref<string[]>([])
    const initialNgramSelectionCount = computed(() => initialNgramSelection.value.length)

    /**
     * Selected items (n-grams or search terms) for the kw destination
     */
    const finalItemSelection = ref<string[]>([])
    const finalItemSelectionCount = computed(() => finalItemSelection.value.length)

    const selectedNGramItems = computed(() =>
        nGramItems.value.filter(
            ngram => ngram.ngram && initialNgramSelection.value.includes(ngram.ngram)
        )
    )

    const shouldClearQueue = ref(false)

    const allNGramsSelected = computed<boolean>(() => {
        return (
            (searchedNgramItems.value.length &&
                initialNgramSelectionCount.value === searchedNgramItems.value?.length) ||
            false
        )
    })

    function toggleAllNgrams() {
        if (!allNGramsSelected.value) {
            selectAllNgrams()
        } else {
            clearAllNgrams()
        }
    }

    function selectAllNgrams() {
        initialNgramSelection.value = searchedNgramItems.value?.map(item => item.ngram) ?? []
    }

    function clearAllNgrams() {
        initialNgramSelection.value = []
    }

    function clearAllNegatives() {
        finalItemSelection.value = []
    }

    function selectNgram(ngram: string) {
        const checked = !initialNgramSelection.value.find(_ngram => _ngram === ngram)

        if (checked) {
            initialNgramSelection.value.push(ngram)
        } else {
            initialNgramSelection.value = initialNgramSelection.value.filter(
                _ngram => _ngram !== ngram
            )
        }
    }

    watch(currentStepIndex, async nextStep => {
        await nextTick()
        // reset scroll position between steps
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto',
        })
    })

    watch(currentRoute, (oldVal, newVal) => {
        // Don't reset the state if the user is moving back and forth between select and confirm states
        if (
            oldVal.matched.some(record => record.name === Routes.ToolkitNGramFinderSearch) &&
            newVal.matched.some(record => record.name === Routes.ToolkitNGramFinderSearch)
        ) {
            return
        }
        if (
            oldVal.matched.some(record => record.name === Routes.ToolkitNGramFinderPmax) &&
            newVal.matched.some(record => record.name === Routes.ToolkitNGramFinderPmax)
        ) {
            return
        }
        if (
            oldVal.matched.some(record => record.name === Routes.ToolkitNGramFinderShopping) &&
            newVal.matched.some(record => record.name === Routes.ToolkitNGramFinderShopping)
        ) {
            return
        }

        hideSnackbar()
        applyCampaignSelection()
        resetState()
    })

    // Campaigns Selection Table
    const {
        data: nGramCampaigns,
        loading: nGramCampaignsLoading,
        isValidating: nGramCampaignsValidating,
    } = useAPI<NgramTool.NgramCampaign[]>(Endpoint.GetNgramCampaigns, {
        body: () => ({
            accountId: accountId.value,
            lookbackWindow: lookbackWindow.value.value,
            groupId: groupId.value,
        }),
        uniqueId: () => `${accountId.value}:${lookbackWindow.value.value}:${groupId.value}`,
        waitFor: () => accountId.value && lookbackWindow.value.value && groupId.value,
    })

    const searchQuery = ref<string>('')

    const { data: sharedSetData, mutate: mutateSharedSetData } = useAPI<
        {
            shared_set_id: number
            shared_set_name: string
            shared_set_resource_name: string
        }[]
    >(Endpoint.GetSharedNegativeLists, {
        body: { type: 'keywords', accountId: accountId.value },
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    const { data: activeCampaignData, loading: activeCampaignsLoading } = useAPI<
        {
            advertising_channel_type: Gads.enums.AdvertisingChannelType
            campaign_id: string
            campaign_name: string
            adgroups_data: {
                adgroup: string
                adgroup_id: number
                campaign_id: string
            }[]
        }[]
    >(Endpoint.GetCampaignsAndAdgroups, {
        body: { campaign_status_enabled: true, accountId: accountId.value },
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    const campaignData = computed(() => {
        const activeCampaigns = activeCampaignData.value || []

        return activeCampaigns.length ? activeCampaigns : []
    })

    const newNegativeListCampaigns = computed<{ value: string; label: string }[]>(() => {
        return campaignData.value.map(campaign => {
            return { value: campaign.campaign_id.toString(), label: campaign.campaign_name }
        })
    })

    const filteredCampaigns = computed(() => {
        return (
            nGramCampaigns.value?.filter(campaign => {
                if (performanceMaxActive.value) return campaign.campaignType === 2
                if (shoppingActive.value) return campaign.campaignType === 1
                return campaign.campaignType === 0
            }) ?? []
        )
    })

    const relevantCampaignIds = computed(() =>
        filteredCampaigns.value
            ?.filter(campaign => !excludedCampaigns.value?.includes(campaign.campaignId))
            .map(campaign => campaign.campaignId)
    )

    const selectedFilteredCampaigns = computed(() => {
        if (!stringifiedCampaignSelection.value) return []

        // Get the campaign selection from the stringified version, which is only updated when the user changes the selection
        const campaignIds = JSON.parse(stringifiedCampaignSelection.value)

        return filteredCampaigns.value.filter(campaign => campaignIds.includes(campaign.campaignId))
    })

    const insightsMode = ref(false)
    const insightNgramCount = computed(
        () =>
            searchedNgramItems.value.filter(
                ngram =>
                    ngram.hasPoorEngagement ||
                    ngram.hasPoorIndustryPerformance ||
                    ngram.hasLowest5PctCTR
            ).length
    )

    const totalCheckedFilters = computed(() => {
        return checkedFilters.value + (insightsMode.value ? 1 : 0)
    })

    const computedBody = computed(() => {
        const filters: NgramTool.NgramFilters = {
            excludeOneWordNGrams: excludeNGramFilters.value.oneWordNGrams.checked,
            excludeTwoWordNGrams: excludeNGramFilters.value.twoWordNGrams.checked,
            excludeThreeWordNGrams: excludeNGramFilters.value.threeWordNGrams.checked,
            excludeStopwords: excludeNGramFilters.value.stopWords.checked,
            excludeSearchPartners: excludeSearchTermFilters.value.searchPartners.checked,
            excludeSearchTermsBlockedByNegatives:
                excludeSearchTermFilters.value.searchTermsBlockedByNegatives.checked,
            excludePausedKeywords: excludeKeywordFilters.value.pausedKeywords.checked,
            excludeExactMatchs: excludeMatchTypeFilters.value.exactMatch.checked,
            excludeExactMatchCloseVariants:
                excludeMatchTypeFilters.value.exactMatchCloseVariant.checked,
            excludePhraseMatchs: excludeMatchTypeFilters.value.phraseMatch.checked,
            excludePhraseMatchCloseVariants:
                excludeMatchTypeFilters.value.phraseMatchCloseVariant.checked,
            excludeBroadMatchs: excludeMatchTypeFilters.value.broadMatch.checked,
            showOnlyNgramsWithInsights: insightsMode.value,
        }

        return {
            accountId: accountId.value,
            lookbackWindow: lookbackWindow.value.value,
            campaignIds: relevantCampaignIds.value,
            groupId: groupId.value,
            filters,
        }
    })

    // This uniqueId will revalidate the data when changed (e.g. when the user selects a new campaign)
    const uniqueId = computed(
        () =>
            `${accountId.value}:${stringifiedFilters.value}:${stringifiedCampaignSelection.value}:${lookbackWindow.value.value}`
    )

    function applyFilters() {
        stringifiedFilters.value = JSON.stringify(computedBody.value.filters)
    }

    const campaignsPopoutOpen = ref(false)

    function applyCampaignSelection() {
        stringifiedCampaignSelection.value = JSON.stringify(relevantCampaignIds.value)
        campaignsPopoutOpen.value = false
    }

    // Restore the campaign selection from the stringified version, which is only applied in the function above
    function cancelCampaignSelection() {
        excludedCampaigns.value = filteredCampaigns.value
            .map(c => c.campaignId)
            .filter(id => !JSON.parse(stringifiedCampaignSelection.value).includes(id))
        campaignsPopoutOpen.value = false
    }

    const {
        data: nGramDataWrapped,
        isValidating,
        loading,
        mutate: mutateNGramData,
        error: nGramDataError,
    } = useAPI<NgramTool.NgramDataWrapped>(Endpoint.GetNgramData, {
        body: () => computedBody.value,
        uniqueId: () => uniqueId.value,
        waitFor: () =>
            !nGramCampaignsLoading.value &&
            stringifiedFilters.value &&
            lookbackWindow.value.value &&
            !activeCampaignsLoading.value &&
            groupId.value,
    })

    // "deservesProperLoading" is used to avoid the loading state flashing as swrv re-validates for whatever reason
    const deservesProperLoading = ref(false)
    watch(uniqueId, () => {
        if (isValidating.value) {
            deservesProperLoading.value = true
        }
    })

    watch(isValidating, () => {
        if (!isValidating.value) {
            deservesProperLoading.value = false
        }
    })

    const nGramCacheState = ref<{
        isCached: boolean
        reason?: keyof typeof loadingStateMessages
    }>({
        isCached: false,
    })

    const nGramDataLoading = computed(() => {
        if (nGramCampaigns.value && selectedFilteredCampaigns.value.length === 0) return false // For showing the empty state where there are no campaigns

        if (loading.value || deservesProperLoading.value) return true

        // Can't use the app without these 2 cached
        if (
            nGramCacheState.value.reason === 'SEARCH_TERM_VIEW_NOT_CACHED' ||
            nGramCacheState.value.reason === 'BLOCKED_SEARCH_TERM_NOT_CACHED' ||
            nGramCacheState.value.reason === 'GA4_SESSIONS_NOT_CACHED'
        )
            return true

        // Can't use the pMax without this cached
        if (
            nGramCacheState.value.reason === 'SEARCH_TERM_INSIGHT_NOT_CACHED' &&
            performanceMaxActive.value
        )
            return true

        return !nGramCacheState.value?.isCached
    })

    const needsLooserFilters = computed(() => {
        return (
            nGramDataWrapped.value?.ngramRows.length === 0 &&
            selectedFilteredCampaigns.value?.length > 0 &&
            !nGramDataLoading.value &&
            !nGramCampaignsLoading.value &&
            !nGramDataError.value &&
            !isValidating.value &&
            !campaignsPopoutOpen.value &&
            !filtersModalOpen.value &&
            !(performanceMaxActive.value && showPMaxOptInMessage.value)
        )
    })

    watch(needsLooserFilters, needsLooser => {
        if (needsLooser) {
            showSnackbar({
                message: `No n-grams found matching your filters`,
                actionText: 'Update Filters',
                indefiniteTimeout: true,
                spinner: false,
                actionHandler: () => {
                    filtersModalOpen.value = true
                },
                snackbarBottom: 108,
            })
        } else {
            hideSnackbar()
        }
    })

    const allCampaignsSelected = computed(() => {
        if (!filteredCampaigns.value?.length) return false
        return relevantCampaignIds.value.length === filteredCampaigns.value.length
    })

    function selectAllCampaigns() {
        if (!allCampaignsSelected.value) {
            excludedCampaigns.value = []
            return
        }

        excludedCampaigns.value =
            filteredCampaigns.value?.map(campaign => campaign.campaignId) ?? []
    }

    function toggleCampaign(campaignId: string) {
        if (excludedCampaigns.value?.includes(campaignId)) {
            excludedCampaigns.value = excludedCampaigns.value?.filter(
                campaign => campaign !== campaignId
            )
            return
        }

        excludedCampaigns.value = [...(excludedCampaigns.value ?? []), campaignId]
    }

    const campaignSelectionCount = computed(() => selectedFilteredCampaigns.value?.length)
    const noCampaignsSelected = computed(
        () =>
            nGramCampaigns.value &&
            !nGramCampaignsValidating.value &&
            campaignSelectionCount.value === 0
    )

    // nGram Selection State
    const ngramAverage = computed(() => {
        if (!nGramDataWrapped.value) {
            return 0
        }

        let metricFromBackend =
            nGramDataWrapped.value.averagePerCampaignType[currentAdvertisingChannelType.value]
                .performanceMetric

        if (performanceMaxActive.value) {
            metricFromBackend = metricFromBackend * 1000 // Pmax metrics are perMille
        }

        return metricFromBackend
    })

    const performanceMetricType = computed(() =>
        performanceMode.value === Targets.PerformanceMode.CPA
            ? performanceMaxActive.value
                ? 'cpm'
                : 'cpa'
            : performanceMaxActive.value
              ? 'vpm'
              : 'roas'
    )

    function generateNscoreColor(nscore: number | null) {
        if (nscore === null) return '#e6e6e6' // grey
        if (nscore >= 0.7) return '#FF2828' // red
        if (nscore > 0.5) return '#FF9500' // amber
        return '#00a861' // green
    }

    const avgEngagementRate = computed(() => {
        const campaigns = selectedFilteredCampaigns.value

        return sumBy(campaigns, c => c.ga4EngagedSessions) / sumBy(campaigns, c => c.ga4Sessions)
    })

    const avgCTR = computed(() => {
        return nGramDataWrapped.value?.insightsContext.campaignAvgCtr
    })

    const nGramItems = computed(() => {
        if (!nGramDataWrapped.value) return []

        return formatItemsForPanels(nGramDataWrapped.value.ngramRows)
    })

    // Ngram search
    const searchedNgramText = ref('')

    const searchedNgramItems = computed(() => {
        if (!searchedNgramText.value) {
            // No search text, return all ngrams
            return nGramItems.value
        }

        const searchText = searchedNgramText.value.toLowerCase()

        return nGramItems.value.filter(item => item.ngram.toLowerCase().includes(searchText))
    })

    // Spread Bar
    const SPREAD_CHART_LENGTH = 12
    const spreadChartItems = computed(() =>
        orderBy(nGramItems.value, nGramItem => nGramItem.nscore ?? -Infinity, 'desc').slice(
            0,
            SPREAD_CHART_LENGTH
        )
    )

    const campaignTableHeaders = computed(() => [
        {
            key: 'campaignName',
            text: 'Campaign',
            noPadding: true,
            vPadding: '0.875rem',
            sortable: false,
        },
        {
            key: 'cost',
            text: 'Cost',
            vPadding: '0.875rem',
            width: 102,
            sortable: true,
        },
        performanceMode.value === Targets.PerformanceMode.CPA
            ? {
                  key: 'conversions',
                  text: 'Conv.',
                  vPadding: '0.875rem',
                  width: 96,
                  sortable: true,
              }
            : {
                  key: 'conversionValue',
                  text: 'Value',
                  vPadding: '0.875rem',
                  width: 97,
                  sortable: true,
              },
        performanceMode.value === Targets.PerformanceMode.CPA
            ? {
                  key: 'cpa',
                  text: 'CPA',
                  vPadding: '0.875rem',
                  width: 96,
                  sortable: true,
              }
            : {
                  key: 'roas',
                  text: 'ROAS',
                  vPadding: '0.875rem',
                  width: 98,
                  sortable: true,
              },
        {
            key: 'campaignGroupName',
            text: 'Campaign Group',
            vPadding: '0.875rem',
            noPadding: true,
            width: 240,
            sortable: true,
        },
    ])

    const campaignTableItems = computed(() =>
        filteredCampaigns.value
            .filter(campaign => {
                if (!searchQuery.value) return true
                return campaign.campaignName.toLowerCase().includes(searchQuery.value.toLowerCase())
            })
            .map(row => {
                const cpa = row.cost > 0 ? row.cost / row.conversions : 0
                const roas = row.conversionValue > 0 ? row.conversionValue / row.cost : 0

                return {
                    ...row,
                    cpa,
                    roas,
                }
            })
    )

    watch(nGramCampaigns, () => {
        if (nGramCampaigns.value) {
            if (excludedCampaigns.value === null) {
                // If the user has never adjusted campaign selection, default to non-campaign group campaigns
                const toExclude = nGramCampaigns.value
                    .filter(c => c.campaignGroupIsBranding)
                    .map(c => c.campaignId)

                excludedCampaigns.value = [...toExclude]
            }

            applyCampaignSelection()
            applyFilters()
        }
    })

    // Keyword Destinations Logic
    const newNegativeListRn = ref('')
    const onPushError = ref({ status: false, message: '' })
    // TODO(nGram): change the name
    const addingNgramsToNegative = ref(false)

    const accountLevelEntity = ref({
        id: 'entity-id',
        label: 'Account Level',
        type: 'account-level',
        checked: false,
    })

    const destinations = computed(() => [
        {
            key: 'negative-list',
            name: 'Negative Lists',
            active: true,
            count: accountLevelEntity.value.checked ? 1 : 0,
        },
        ...(!performanceMaxActive.value
            ? [{ key: 'campaign', name: 'Campaigns', active: false }]
            : []),
    ])

    const existingSharedSetNames = computed(() =>
        sharedSetData.value?.map(set => set.shared_set_name)
    )

    // Snackbar logic

    const snackBarMessage = computed(
        () =>
            !!selectedFilteredCampaigns.value.length &&
            nGramCacheState.value?.reason &&
            loadingStateMessages[nGramCacheState.value.reason]
    )

    const cacheSetInterval = ref<ReturnType<typeof setInterval>>()

    function setupSetInterval() {
        if (cacheSetInterval.value) clearInterval(cacheSetInterval.value)

        cacheSetInterval.value = setInterval(async () => {
            await checkCache()
        }, 1_000)
    }

    async function checkCache() {
        const res = await authRequest<{
            isCached: boolean
            reason?: keyof typeof loadingStateMessages
        }>(Endpoint.CheckNgramCache, {
            body: {
                groupId: groupId.value,
                accountId: accountId.value,
                lookbackWindow: lookbackWindow.value.value,
            },
        })

        if (
            !performanceMaxActive.value &&
            res.isCached === false &&
            res.reason === 'SEARCH_TERM_INSIGHT_NOT_CACHED'
        ) {
            // Pretend that we're all cached and ready if we're waiting for the search term insight, but we're on another tab
            nGramCacheState.value = { isCached: true }
        } else {
            nGramCacheState.value = res
        }

        if (res.isCached) {
            cacheSetInterval.value && clearInterval(cacheSetInterval.value)
        }
    }

    watch(lookbackWindow, async () => {
        nGramCacheState.value = {
            isCached: false,
        }

        await setupSetInterval()
    })

    onBeforeUnmount(() => {
        cacheSetInterval.value && clearInterval(cacheSetInterval.value)
        hideSnackbar()
        filtersModalOpen.value = false
    })

    const intercom = useIntercom()
    onMounted(() => setupSetInterval())
    onMounted(() => {
        if (currentStepIndex.value === 1) {
            goToStep0()
        }

        intercom.trackEvent('ngram_finder_opened')
    })

    // Refresh data if needed logic
    watch(snackBarMessage, async (newVal, oldVal) => {
        if (newVal && !performanceMaxActive.value) {
            showSnackbar({
                message: newVal,
                indefiniteTimeout: true,
                snackbarBottom: 104,
            })

            await setupSetInterval()
        }

        if (newVal && !oldVal) {
            return authRequest(Endpoint.DispatchHighPriorityNgramJob, {
                body: {
                    accountId: accountId.value,
                    lookbackWindow: lookbackWindow.value.value,
                },
            })
        }

        if (!newVal && oldVal) {
            await mutateNGramData()
            nGramCacheState.value = {
                isCached: true,
            }

            cacheSetInterval.value && clearInterval(cacheSetInterval.value)
            hideSnackbar()

            return
        }

        if (!newVal) {
            hideSnackbar()

            return
        }
    })

    // pMax Opt in logic
    const { data: pmaxRequestProgressStatus, mutate: checkIfPmaxAnalysisStillInProgress } = useAPI<
        'needs_request' | 'in_progress' | 'ready'
    >(Endpoint.GetPmaxRequestProgressStatus, {
        body: () => ({ accountId: accountId.value }),
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    const pMaxOptInButton = ref<typeof oButton>()

    const showPMaxOptInMessage = computed(() => {
        if (!performanceMaxActive.value) return false

        const performanceMaxCampaignCount =
            countBy(
                nGramCampaigns.value,
                c => c.campaignType === NgramTool.campaignTypeEnum.PERFORMANCE_MAX
            ).true ?? 0

        if (performanceMaxCampaignCount === 0) return false

        return pmaxRequestProgressStatus.value !== 'ready'
    })

    async function optInToPMax() {
        await authRequest(Endpoint.RequestPmaxAnalysis, {
            body: {
                accountId: accountId.value,
            },
        })
        await checkIfPmaxAnalysisStillInProgress()
    }

    watch(pmaxRequestProgressStatus, newVal => {
        if (newVal === 'in_progress') {
            pmaxStatusCheckUntilReady()
        }
        if (newVal === 'ready' && performanceMaxActive.value) {
            mutateNGramData()
        }
    })

    async function pmaxStatusCheckUntilReady() {
        while (pmaxRequestProgressStatus.value === 'in_progress') {
            await delay(3000)
            await checkIfPmaxAnalysisStillInProgress()
        }
    }

    // Util functions
    function calculatePerformanceMetric(
        impressions: number,
        cost: number,
        conversions: number,
        conversionValue: number
    ) {
        if (performanceMaxActive.value) {
            if (performanceMode.value === Targets.PerformanceMode.CPA) {
                return conversions > 0 ? (conversions / impressions) * 1000 : 0 // CPM
            }
            return conversionValue > 0 ? (conversionValue / impressions) * 1000 : 0 // VPM
        }

        if (performanceMode.value === Targets.PerformanceMode.CPA) {
            return calculateCpaClamped({ cost, conversions }) // CPA
        }

        return conversionValue > 0 ? conversionValue / cost : 0 // ROAS
    }

    function generateVsAvgColor(vsAvg: number) {
        const merticIsInverted =
            !performanceMaxActive.value && performanceMode.value === Targets.PerformanceMode.CPA

        return (vsAvg > 0 && merticIsInverted) || (vsAvg < 0 && !merticIsInverted) ? 'red' : 'green'
    }

    function formatCpm(cpm: number) {
        if (cpm >= 1 || cpm === 0) return useNumber({ value: cpm }).displayValue.value
        return cpm.toPrecision(2)
    }

    function formatVpm(vpm: number) {
        if (vpm >= 1 || vpm === 0) return useDomainMoney({ value: vpm }).value.displayValue.value
        return `${currencySymbol.value}${vpm.toPrecision(2)}`
    }

    function formatKeyword({
        keyword,
        matchType,
    }: {
        keyword: string
        matchType: Gads.enums.KeywordMatchType
    }): string {
        if (matchType === Gads.enums.KeywordMatchType.PHRASE) {
            return `"${keyword}"`
        }
        if (matchType === Gads.enums.KeywordMatchType.EXACT) {
            return `[${keyword}]`
        }
        return keyword
    }

    const formatItemsForPanels = <T extends NGramRes | SearchTermRes>(
        items: T[]
    ): PanelItem<T>[] => {
        return items.map(item => {
            let text: string
            let entityType: PanelItem['entityType']
            let nScoreColor: string
            let nscoreSortValue: number

            if (isNGramRes(item)) {
                text = item.ngram
                entityType = Improvement.LocationEntity.NGram
                nScoreColor = generateNscoreColor(item.nscore ?? null)
                nscoreSortValue = item.nscore ?? -Infinity
            } else {
                text = item.searchTerm
                entityType = Improvement.LocationEntity.SearchTerm
                nScoreColor = generateNscoreColor(null)
                nscoreSortValue = -Infinity
            }

            const { impressions = 0, cost = 0, conversions = 0, conversionValue = 0 } = item

            const performanceMetric = calculatePerformanceMetric(
                impressions,
                cost,
                conversions,
                conversionValue
            )

            const vsAvg = (performanceMetric - ngramAverage.value) / ngramAverage.value
            const vsAverageColor = generateVsAvgColor(vsAvg)

            const performanceProperties: NGramPerformance = {
                cost,
                conversions,
                conversionValue,
                impressions,
                cpa: performanceMetric,
                roas: performanceMetric,
                cpm: performanceMetric,
                vpm: performanceMetric,
                vsAvg,
                vsAverageColor,
                nScoreColor,
                nscoreSortValue,
            }

            const formattedItem = {
                ...item,
                ...performanceProperties,
                text,
                entityType,
            }

            return formattedItem
        })
    }

    function downloadNgramCsv() {
        const items = orderBy(
            nGramItems.value.map(item => {
                const primaryColumns = {
                    'Account Name': accountInfo.value?.name,
                    Account: accountInfo.value?.idOnPlatform,
                    'N-Gram': item.ngram,
                }

                // CPM and VPM need more precision
                const performanceMetricRounding = performanceMaxActive.value === true ? 5 : 2

                const ctr = (item.clicks ?? 0) / (item.impressions ?? 1)

                const metricsColumns = {
                    Clicks: item.clicks,
                    Impressions: item.impressions,
                    CTR: round(ctr, 2),
                    Conversions: round(item.conversions, 2),
                    'Conversion Value': round(item.conversionValue, 2),
                    [performanceMetricType.value.toUpperCase()]: round(
                        item[performanceMetricType.value],
                        performanceMetricRounding
                    ),
                    [`${performanceMetricType.value.toUpperCase()} Avg`]: round(
                        ngramAverage.value,
                        performanceMetricRounding
                    ),
                    [`${performanceMetricType.value.toUpperCase()} vs Avg`]: round(item.vsAvg, 2),
                }

                const insightColumns = {
                    'Underperforming in Industry': item.hasPoorIndustryPerformance
                        ? 'TRUE'
                        : 'FALSE',
                    'GA4 Total Sessions': item.ga4Sessions,
                    'GA4 Engaged Sessions': item.ga4EngagedSessions,
                    'GA4 Engagement Rate': item.ga4Sessions
                        ? round(item.ga4EngagedSessions / item.ga4Sessions, 2)
                        : 0,
                    'Flagged for High Bounce Rate': item.hasPoorEngagement,
                    'Flagged for Low CTR': item.hasLowest5PctCTR,
                }

                if (performanceMaxActive.value) {
                    return {
                        ...primaryColumns,
                        ...metricsColumns,
                        ...insightColumns,
                    }
                } else {
                    return {
                        ...primaryColumns,
                        Cost: round(item.cost, 2),
                        ...metricsColumns,
                        'Number of Contributing Search Terms': item.searchTerms.length,
                        ...insightColumns,
                    }
                }
            }),
            // @ts-expect-error impressions is defined when performanceMaxActive is true, and vice-versa
            r => (performanceMaxActive.value ? r.Impressions : r.Cost),
            'desc'
        )

        const formattedLookbackWindow = lookbackWindow.value.label.replace('Last ', '')

        downloadCsv({
            dataSet: `${accountName.value} - Ngrams - ${formattedLookbackWindow}`,
            columnHeaders: Object.keys(items[0]),
            items,
        })
    }

    // Change history logic
    const {
        data: changeHistory,
        loading: changeHistoryLoading,
        mutate: mutateChangeHistory,
    } = useAPI<
        // TODO: grab the correct type when released
        {
            date: Date
            keyword: string
            matchType: Gads.enums.KeywordMatchType
            negativeKeywordsDestinations: NgramTool.NegativeKeywordsDestinations
            userName: string
            profileUrl: string | null
        }[]
    >(Endpoint.GetNgramToolHistory)

    // If for any reason somebody on a legacy plan has found a way to get into the ngram tool, redirect them to the tools page

    if (isOnLegacyPlan.value) {
        push({ name: Routes.ToolkitTools })
    }
    watch(isOnLegacyPlan, newVal => {
        if (newVal) {
            push({ name: Routes.ToolkitTools })
        }
    })

    const toProvide = {
        // Navigation
        tabLinks,
        steps,
        currentStepIndex,
        goToNGramSelection,
        goToNegativeDestination,
        closeNGramTool,
        currencyCode,
        searchCampaignsActive,
        performanceMaxActive,
        shoppingActive,
        downloadNgramCsv,
        resetState,

        // NGram Tables
        nGramItems,
        searchedNgramItems,
        selectedNGramItems,
        allNGramsSelected,
        initialNgramSelection,
        initialNgramSelectionCount,
        toggleAllNgrams,
        clearAllNgrams,
        selectNgram,
        shouldClearQueue,
        nGramDataLoading,
        addingNgramsToNegative,
        finalItemSelection,
        finalItemSelectionCount,
        mutateNGramData,
        searchedNgramText,
        avgEngagementRate,
        avgCTR,

        // Filters Logic
        applyFilters,
        campaignsPopoutOpen,
        applyCampaignSelection,
        cancelCampaignSelection,
        uniqueId,
        insightNgramCount,
        insightsMode,
        totalCheckedFilters,

        // Campaign Table
        campaignTableHeaders,
        campaignTableItems,
        campaignSelectionCount,
        noCampaignsSelected,
        searchQuery,
        allCampaignsSelected,
        selectAllCampaigns,
        toggleCampaign,
        ngramAverage,
        relevantCampaignIds,

        // Indicator
        SPREAD_CHART_LENGTH,
        spreadChartItems,
        performanceMetricType,

        // Keywords Destinations
        destinations,
        accountLevelEntity,
        newNegativeListCampaigns,
        existingSharedSetNames,
        onPushError,
        sharedSetData,
        campaignData,
        newNegativeListRn,

        // Adding the Negatives
        mutateSharedSetData,

        showPMaxOptInMessage,
        optInToPMax,
        pmaxRequestProgressStatus,
        pMaxOptInButton,

        // Util functions
        calculatePerformanceMetric,
        formatItemsForPanels,
        generateVsAvgColor,
        formatCpm,
        formatVpm,
        formatKeyword,

        // Change History
        changeHistory,
        changeHistoryLoading,
        mutateChangeHistory,
    }

    provide('nGramTool', toProvide)

    return toProvide
}

export function useNGramTool() {
    const injected = inject<ReturnType<typeof provideNGramTool>>('nGramTool')

    if (!injected) {
        throw new Error(
            `useNGramTool not yet injected, something is wrong. useNGramTool() can only be called in a /ngram/ route.`
        )
    }

    return injected
}
