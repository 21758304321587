<template>
    <Modal
        v-model="open"
        :title="Object.keys(queuedLinkedAccounts).length > 1 ? 'Unlink Accounts' : 'Unlink Account'"
        @close="closeModal"
    >
        <template #content>
            <Container
                color="background-plus-one"
                :borderRadius="20"
                :gap="0"
                :width="
                    above480 ? (Object.keys(queuedLinkedAccounts).length > 1 ? 358 : 354) : '100%'
                "
                hideOverflow
                :padding="[18, 22, 22]"
            >
                <Stack direction="column" align="start" :gap="18">
                    <Text size="body-small">
                        Please note, this will unlink the selected
                        {{ Object.keys(queuedLinkedAccounts).length > 1 ? 'accounts' : 'account' }}
                        for <b>everyone on your team</b>.
                        <span v-if="currentUserIsConnectedToAnyQueuedAccounts">
                            To disconnect the selected
                            {{
                                Object.keys(queuedLinkedAccounts).length > 1
                                    ? 'accounts'
                                    : 'account'
                            }}
                            from your account only, click below.
                        </span>
                        <span v-else>Click below to confirm.</span>
                    </Text>
                    <Button
                        v-if="currentUserIsConnectedToAnyQueuedAccounts"
                        color="secondary"
                        @click="updateLinkedAccountStatus(false, true)"
                        :loading="updatingLinkedAccounts"
                    >
                        <template #before>
                            <ProfileImage
                                :size="16"
                                :imageUrl="profileImageUrl"
                                :boringAvatar="getAvatarUrl(userId)"
                            />
                        </template>
                        Disconnect {{ userInfo?.name }}
                    </Button>
                </Stack>
            </Container>
        </template>
        <template #buttons>
            <Button @click="closeModal" color="secondary">Cancel</Button>
            <Button
                @click="updateLinkedAccountStatus(false)"
                :loading="updatingLinkedAccounts"
                iconBefore="Unlink"
                color="red"
            >
                Confirm and Unlink
            </Button>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, watch } from 'vue'

// Composition
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'

// Components
import {
    Modal,
    Button,
    Stack,
    Container,
    Text,
    ProfileImage,
    useMediaQuery,
} from '@opteo/components-next'

// Props
const props = defineProps({
    modelValue: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Emits
const emit = defineEmits(['update:modelValue'])

// Setup
const open = ref(false)
const { getAvatarUrl } = useBoringAvatar()
const { above480 } = useMediaQuery()
const {
    currentUserIsConnectedToAnyQueuedAccounts,
    queuedLinkedAccounts,
    updateLinkedAccountStatus,
    profileImageUrl,
    userId,
    userInfo,
    updatingLinkedAccounts,
} = useLinkedAccounts()

// Functions
function closeModal() {
    open.value = false
}

// Watch
watch(props, async ({ modelValue }) => {
    open.value = modelValue
})

watch(open, async newVal => {
    emit('update:modelValue', newVal)
})
</script>

<style scoped lang="scss"></style>
